import { Input, notification, Radio } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { repayWithMoMoAction } from "../../../store/pos/actions";
import { LoadingButton } from "@mui/lab";
import { myLayoutActions } from "../../../store/layout";
import { myPosActions } from "../../../store/pos";
import CustomModal from "../../../components/Modals/CustomModal";

const ConfirmRepayment = () => {
  const { customer, pos, layout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedNumber, setSelectedNumber] = useState("");
  const [openConfimModal, setOpenConfirmModal] = useState(false);

  const onChange = async (e: any) => {
    setSelectedNumber(e.target.value);
  };

  const customerData = customer?.customerByPhone;

  const customerPhoneNumbers = (phoneNumbers: any) => {
    if (
      !phoneNumbers ||
      !phoneNumbers.phone ||
      !Array.isArray(phoneNumbers.otherPhone)
    ) {
      return [];
    }
    return [
      {
        tel: phoneNumbers.phone,
      },
      ...phoneNumbers.otherPhone,
    ];
  };

  const handlerValidation = async () => {
    const phone = selectedNumber?.replace(/\s/g, "");
    if (!phone || phone.length < 12) {
      notification.error({ message: "Invalid phone number" });
      return;
    }
    if (layout?.amountToPay === 0) {
      notification.error({ message: "Please add Amount" });
      return;
    }
  };

  const handleOpenModal = async () => {
    await handlerValidation();

    setOpenConfirmModal(true);
  };

  const handleCloseModal = async () => {
    setOpenConfirmModal(false);
  };

  const confirmPayment = async (e: any) => {
    e.preventDefault();
    await handleCloseModal();
    dispatch(myPosActions.setRepaymentStatusResult(null));
    const phone = selectedNumber?.replace(/\s/g, "");
    await handlerValidation();

    if (phone && layout?.amountToPay !== 0) {
      await repayWithMoMoAction({
        phone,
        accountId: layout?.selectedInvoiceToPay?.account,
        amountToCollect: layout?.amountToPay,
      })(dispatch);
    }
  };

  const handleAmountToPay = (e: any) => {
    const numberValue = Number(e.target.value);
    dispatch(myLayoutActions.setAmountToPay(numberValue));
  };

  return (
    <>
      <div className="text-[#030229]">
        <h1 className="text-[18px] opacity-80 my-6 font-medium text-center">
          Choose phone number you’re going to pay with
        </h1>
        <form>
          <div className="mb-8">
            <Radio.Group
              onChange={onChange}
              value={selectedNumber}
              className="flex-col md:flex fflex-row 2xl:flex-col gap-x-2"
            >
              {customerPhoneNumbers(customerData).map((phone) => (
                <>
                  {phone?.tel && (
                    <div className="flex flex-col border-2 border-gray-100 rounded-lg px-4 py-3 my-3">
                      <div className="flex flex-col gap-x-3">
                        <Radio value={phone?.tel}>{`+${phone?.tel}`}</Radio>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </Radio.Group>
          </div>
          <div>
            <p className="text-left text-gray-800 text-sm  pb-1">
              Or Add Another Phone Number
            </p>

            <div className="md:flex flex-col mt-5 md:mt-0">
              <label className="text-sm font-medium ">Phone Number</label>
              <div className="flex gap-x-2 mt-2">
                <div className="flex  items-center space-x-2 w-full">
                  <PhoneInput
                    value={selectedNumber}
                    onChange={(value) => {
                      setSelectedNumber(value);
                    }}
                    country={"rw"}
                    buttonStyle={{
                      height: "50px",
                      width: "45px",
                      border: "1px solid #E5E7EB",
                    }}
                    inputStyle={{
                      height: "50px",
                      width: "100%",
                      border: "1px solid #E5E7EB",
                      borderRadius: "10px",
                    }}
                    containerClass="phone-container"
                  />
                </div>
              </div>
            </div>
            <div className="md:flex flex-col mt-5 md:mt-2">
              <label className="text-sm font-medium ">Amount</label>
              <div className="flex gap-x-2 mt-2">
                <div className="flex  items-center space-x-2 w-full">
                  <Input
                    value={layout?.amountToPay}
                    onChange={handleAmountToPay}
                    type="number"
                    className="h-12"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-8 mt-9 flex items-center justify-center">
            <LoadingButton
              variant="contained"
              className="w-[250px] h-[45px] text-lg uppercase"
              onClick={handleOpenModal}
              disabled={selectedNumber === "" || pos?.isRepaymentStart}
              loading={pos?.isRepaymentStart}
            >
              CONFIRM PAYMENT
            </LoadingButton>
          </div>
        </form>
      </div>
      <CustomModal
        openModal={openConfimModal}
        width={350}
        component={
          <>
            <div>
              <h1 className="font-semibold text-lg mb-2">Payment details</h1>
              <div className="">
                <div className="flex items-center gap-x-2 justify-between">
                  <p className="text-[15px] opacity-80">Amount to pay</p>
                  <p className="text-[16px] font-medium">{`${layout?.amountToPay?.toLocaleString()} RWF`}</p>
                </div>
                <div className="flex items-center gap-x-2 justify-between">
                  <p className="text-[15px] opacity-80">Telephone</p>
                  <p className="text-[16px] font-medium">{selectedNumber}</p>
                </div>
              </div>
              <div className="mt-4 gap-x-4 flex">
                <LoadingButton variant="outlined" onClick={handleCloseModal}>
                  No, Cancel
                </LoadingButton>
                <LoadingButton variant="contained" onClick={confirmPayment}>
                  Yes, Confirm
                </LoadingButton>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default ConfirmRepayment;
