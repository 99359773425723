import DataActivationAndTrafficCard from "../DataActivationAndTrafficCard";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShopLineChart from "../../../graphy/LineChart";
import { useSelector } from "react-redux";
import { calculatePercentageChange } from "../../../../utils/converter";
import { Card } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const TotalRevenueAndDataActivation = (props: any) => {
  const { posOverView, dashboard, company } = useSelector((state: any) => state);
  const todayRevenue =
    posOverView?.todayRevenueStats?.data?.revenue?.currentDate?.revenueTotal;
  const yesterdayRevenue =
    posOverView?.todayRevenueStats?.data?.revenue?.comparedDate?.revenueTotal;

  const percentageChange = calculatePercentageChange(
    todayRevenue,
    yesterdayRevenue
  );
  return (
    <div className="flex flex-col xl:flex-row gap-x-3  pt-34">
      <div className="relative bg-white  shadow rounded-md p-3 space-y-1 w-full xl:!w-1/2 text-xs">
        <div className="flex flex-col sm:flex-row items-cente justify-around pt-2 sm:pt-10">
          <div>
            <p className="font-Poppins text-lg text-gray-800">Total revenue</p>
            <div className="pt-4 font-semibold text-base">
              {getDefaultCurrencyCode(company)} {posOverView.isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : todayRevenue?.toLocaleString() || 0}</div>
            <div className="pt-3 text-gray-400 font-semibold text-base">
              {getDefaultCurrencyCode(company)} {posOverView.isFetching ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : yesterdayRevenue?.toLocaleString() || 0}
            </div>
          </div>
          <div className="flex gap-4 pt-5 sm:pt-0">
            <div className="text-[10px] flex xl:flex-col gap-2">
              <p className="font-medium text-base text-[#030229]">
                <span className="inline-block h-3 w-3 bg-[#82ca9d] rounded-full"></span>Today
              </p>
            </div>
            <div className="text-[10px] flex xl:flex-col gap-2">
              <p className="font-medium text-base text-[#030229]">
                <span className="inline-block h-3 w-3 bg-[#2943D680] rounded-full"></span>Yesterday
              </p>
            </div>
          </div>
          <div>
            <p
              className={`font-medium  pt-5 sm:pt-0 text-base ${percentageChange?.type === "increase"
                ? "text-green-500"
                : percentageChange?.type === "decrease"
                  ? "text-red-500"
                  : "text-blue-900"
                }`}
            >
              {percentageChange?.type === "increase" ? (
                <ArrowDropUpIcon />
              ) : percentageChange?.type === "decrease" ? (
                <ArrowDropDownIcon sx={{ fontSize: "30px" }} />
              ) : (
                ""
              )}{" "}
              {`${percentageChange?.percentage}%`}
            </p>
          </div>
        </div>

        <div className="xl:flex xflex-wrap gap-y-3 rounded-md px-3 py-1 h-[3d0vh]">

          <div className="w-[95%] mt-6 pt-20">
            <ShopLineChart />
            {/* <LineChart
                  data={data}
                  // prefix="${getDefaultCurrencyCode(company)} "
                  // xtitle="Time"
                  // ytitle="Amount"
                  // thousands=","
                /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-1 mt-5 sm:mt-0 bg-white flex-col shadow rounded-md p-5 text-xs">
        <Card className="flex-1 min-w-[90px] space-y-3">
          <p className="flex flex-row font-semibold text-base">Data Activation</p>
          <div className="flex flex-row gap-4">
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]"></h1>}
              text="Pieces"
              amount={props?.currentDateProp || 0}
              comparedAmount={props?.comparedDateProp || 0}
              chartData={data}
            />
          </div>
        </Card>
        <Card className="flex-1 min-w-[200px] space-y-3 mt-5 sm:mt-0">
          <p className="font-semibold text-base capitalize">
            Traffic Real time statistic
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">Completed Purchase</h1>}
              text="Pieces"
              amount={dashboard?.hourRevenueStats?.data.quantity?.currentDate?.totalQuantity || 0}
              comparedAmount={dashboard?.hourRevenueStats?.data.quantity?.comparedDate?.totalQuantity || 0}
              chartData={data}
            />
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">Pending Leads</h1>}
              text="Pieces"
              amount={dashboard?.pendingDevices?.data?.currentDate?.totalCount || 0}
              comparedAmount={dashboard?.pendingDevices?.data?.comparedDate?.totalCount || 0}
              chartData={data}
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-4 ">
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">No Sale</h1>}
              amount={dashboard?.nosale?.data?.currentDate?.totalQuantity || 0}
              text="Pieces"
              comparedAmount={dashboard?.nosale?.data?.comparedDate?.totalQuantity || 0}
              chartData={data}
            />
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">No Sale Amount</h1>}
              text={getDefaultCurrencyCode(company)}
              amount={(dashboard?.nosale?.data?.currentDate?.totalAmount || 0).toLocaleString()}
              comparedAmount={(dashboard?.nosale?.data?.comparedDate?.totalAmount || 0).toLocaleString()}
              chartData={data}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TotalRevenueAndDataActivation;