import React, { useState } from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import type { RadioChangeEvent } from "antd";
import { useParams } from "react-router-dom";
import { updateShopStockCartDetailsAction } from "../../store/wareHouse/actions";
import { IoMdAlert } from "react-icons/io";
import { ReactComponent as InventoryIcons } from "../../assets/icons/dashboard/fontisto_shopping-pos-machine.svg";
import { ReactComponent as DeviceFinancingIcons } from "../../assets/images/financing-com.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { handleOpenSellingModeAction } from "../../store/layout/actions";
const PamentModeDrower = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const [isActive, setIsActive] = useState<any>(null);
  const [isActiveProduct, setIsActiveProduct] = useState<any>(null);
  const [isDeviceFinancing, setIsDeviceFinancing] = useState(false);

  // const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

  const businessProducts =
    wareHouse?.createdCart?.data?.shop?.channel?.services;

  const dispatch = useDispatch();
  const { cartId } = useParams();

  const onChange = async (e: RadioChangeEvent) => {
    if (e.target.value === "pos") {
      setIsDeviceFinancing(false);
      const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
        isSubscription: e.target.value,
      })(dispatch);
      setIsActive(e.target.value);
      if (res) {
        props.onClick();
        setIsActive(null);
        await handleOpenSellingModeAction(false)(dispatch);
      }
    } else {
      setIsDeviceFinancing(true);
      setIsActive("subscription");
    }
  };

  const handleSelecteBusinessProduct = async (e: RadioChangeEvent) => {
    setIsActiveProduct(e.target.value);
    const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
      isSubscription: "subscription",
      businessProduct: e.target.value,
    })(dispatch);
    if (res) {
      props.onClick();
      setIsActiveProduct(null);
      setIsActive(null);
      setIsDeviceFinancing(false);
      await handleOpenSellingModeAction(false)(dispatch);
    }
  };
  return (
    <div className="min-h-[20rem] w-full">
      <div className="flex flex-col items-center justify-around w-full min-h-[15vh]">
        <Radio.Group
          onChange={onChange}
          value={isActive}
          className=" w-full sm:w-[24rem] flex flex-col"
        >
          {/* {(["admin", "dev"]?.includes(auth?.user?.role) ||
            !auth?.user?.profile) && ( */}
            <Radio
              value={"pos"}
              className={`w-full h-42 rounded-2xl  bg-[#0302290D] 
                flsex p-4 px-5 sm:px-10 gap-4`}
              disabled={
                wareHouse?.isUpdateFetching || wareHouse?.createCartIsFetching
              }
            >
              <div className="flex justify-between divide-x-2 w-full sm:w-[18rem]">
                {/* <div> */}
                <p className="text-[#030229] text-base sm:text-xl mt-3 font-semibold">
                  Cash Sale
                </p>
                {/* </div> */}

                <div className="pl-10 pt-2">
                  <InventoryIcons className="w-14 h-10" />
                </div>
              </div>
            </Radio>
          {/* ) */}
          {/* // } */}
          {/* {(["admin", "dev"]?.includes(auth?.user?.role) ||
            auth?.user?.profile) && ( */}
            <Radio
              value={"subscription"}
              className={`w-full h-42 mt-2 rounded-2xl bg-[#0302290D] 
                flsex p-4 sm:px-10 gap-4`}
              disabled={
                wareHouse?.isUpdateFetching || wareHouse?.createCartIsFetching
              }
            >
              <div className="flex justify-between divide-x-2 w-full sm:w-[18rem]">
                <p className="text-[#030229] text-base sm:text-xl mt-2.5 font-semibold">
                  Device Instalment
                </p>

                <div className="pl-10 pt-2">
                  <DeviceFinancingIcons className="w-14 h-10" />
                </div>
              </div>
            </Radio>
          {/* // )} */}
        </Radio.Group>
        {isDeviceFinancing && (
          <div className="mt-4 max-w-[70%]">
            <Radio.Group
              onChange={handleSelecteBusinessProduct}
              value={isActiveProduct}
              className="flex flex-wrap pb-2"
              disabled={
                wareHouse?.isUpdateFetching || wareHouse?.createCartIsFetching
              }
            >
              {businessProducts
                ?.filter((el: any) => el?.businessProduct?.isPosEnabled)
                ?.map((d: any) => (
                  <Radio
                    value={d?.businessProduct?._id}
                    className={`w-44 h-14 border pl-4 mb-2 rounded-md border-gray-400 flex items-center xjustify-center`}
                  >
                    <span className="text-sm font-semibold">
                      {d?.businessProduct?.name}
                    </span>
                  </Radio>
                ))}
            </Radio.Group>
          </div>
        )}
        {(wareHouse?.isFetching ||
          wareHouse?.isUpdateFetching ||
          wareHouse?.createCartIsFetching) && (
          <div>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
            />
          </div>
        )}
        <div
          className={`flex gap-2 ${
            isDeviceFinancing ? "mt-6" : "mt-14"
          } text-base px-6 py-1 text-[#5F5AFE] border border-[#5F5AFE] rounded-[4px]`}
        >
          <IoMdAlert color="#5F5AFE" size={18} className="mt-1" /> Selecting
          selling mode required! kindly make choice Now!
        </div>
      </div>
    </div>
  );
};

export default PamentModeDrower;
