import React from "react";
import { Badge, Carousel } from "antd";
import { BsExclamationCircleFill } from "react-icons/bs";
import { LoadingButton } from "@mui/lab";
import { getActivePrice } from "../../../utils/converter";

import { myWareHouseActions } from "../../../store/wareHouse";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";

const ProductCard = (props: any) => {
  const { wareHouse, company } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const itemPrice = getActivePrice(
    props?.data?.data?.product?.requestedItem?.product?.prices
  );
  const discardSearchResult = () => {
    dispatch(myWareHouseActions.setSearchResult(null));
  };

  const isSubscription =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const selectedPlan = wareHouse?.createdCart?.data?.selectedPlan;

  const amountTopay =
    selectedPlan?.planType?.toLowerCase() === "prepaid"
      ? selectedPlan?.initialPayment + selectedPlan?.monthlyInstalment
      : selectedPlan?.initialPayment;

  const amount = isSubscription ? amountTopay : itemPrice;

  return (
    <div>
      <Badge.Ribbon
        text=<h1 className="text-[12px] md:text-baselg:text-base pt-1">
          {props?.data?.data?.isInShop ? "In Shop" : "Out"}
        </h1>
        color="#FAAD14"
        className="w-[108px] h-[32px] text-center"
      >
        <div className="border border-gray-100 p-3">
          <div className="sm:flex gap-10">
            <div className="bg-[#E5E5E5] mt-10 sm:mt-0 w-full sm:w-[100px] xl:w-[134px] h-[120px] sm:h-[90px] xl:h-[129px] p-2 rounded-md">
              <Carousel
                autoplay
                className="w-full h-[120px] sm:h-[90px] xl:h-[129px]"
              >
                {props?.data?.data?.product?.requestedItem?.product?.product?.images?.map(
                  (im: any) => (
                    <div>
                      <img
                        src={im}
                        className="w-full h-[100px] sm:h-[90px] xl:h-[129px] rounded-sm"
                        alt=""
                      />
                    </div>
                  )
                )}
              </Carousel>
            </div>
            <div className="mt-3 sm:mt-0">
              <h1 className="text-[#030229] text-sm md:text-lg xl:text-2xl font-medium capitalize">
                {
                  props?.data?.data?.product?.requestedItem?.product?.product
                    ?.model
                }
              </h1>
              <p>
                <span className="text-[#030229B2] text-[12px] md:text-base xl:text-lg">
                  Type:
                </span>{" "}
                <span className="text-[#030229] text-[12px] md:text-base xl:text-lg pl-1">
                  {
                    props?.data?.data?.product?.requestedItem?.product?.product
                      ?.type
                  }
                </span>
              </p>
              <p>
                <span className="text-[#030229B2] text-[12px] md:text-base  xl:text-lg">
                  Brand:
                </span>{" "}
                <span className="text-[#030229] text-[12px] md:text-base  xl:text-lg pl-1">
                  {
                    props?.data?.data?.product?.requestedItem?.product?.product
                      ?.brand
                  }
                </span>
              </p>
              <div className="flex flex-col gap-2">
                {props?.data?.data?.product?.requestedItem?.product?.specification?.map(
                  (data: any, index: number) => {
                    const regex = /\((.*?)\)/;
                    const match = data[0].match(regex);

                    if (match) {
                      const stringFromBracket = match[1];

                      if (
                        !["type", "brand"].includes(
                          stringFromBracket.toLowerCase()
                        )
                      ) {
                        return (
                          <div
                            className="flex text-[#030229] text-[12px] md:text-base  font-normal"
                            key={index}
                          >
                            <p>
                              <span>{`${stringFromBracket}: `}</span>
                            </p>
                            <p className="pl-1">{data[1]}</p>
                          </div>
                        );
                      }
                    }

                    return null;
                  }
                )}
              </div>
              <p>
                <span className="text-[#030229B2] text-[12px] md:text-base  xl:text-lg">
                  Extended Warranty:
                </span>{" "}
                <span className="text-[#030229] text-[12px] md:text-base  xl:text-lg pl-1">
                  {
                    props?.data?.data?.product?.requestedItem?.product
                      ?.extendedWarranty
                  }
                  %
                </span>
              </p>
            </div>
          </div>
          <div className="flex justify-between mt-4">
            <div className="flex gap-2">
              <BsExclamationCircleFill className="mt-1" size={20} />
              <h1 className="text-[#030229] text-[12px] md:text-base  xl:text-base pt-1">
                This device is eligible for Warranty
              </h1>{" "}
            </div>
            <p className="text-[#030229] text-[10px] md:text-base text-lg xl:text-xl font-bold ">{`${getDefaultCurrencyCode(
              company
            )} ${amount?.toLocaleString()}`}</p>
          </div>
        </div>
      </Badge.Ribbon>
      <div className="flex gap-5 justify-end mt-2 xl:mt-10">
        <LoadingButton
          type="submit"
          sx={{
            width: { xs: "120px", sm: "130px", md: "150px" },
            borderRadius: "10px",
          }}
          variant="outlined"
          onClick={discardSearchResult}
          style={{ padding: "6px" }}
          loading={wareHouse?.isFetching}
        >
          {" "}
          <h1 className="text-[#5F5AFE] text-sm xl:text-base font-medium">
            {"Discard"}
          </h1>
        </LoadingButton>
        <LoadingButton
          type="submit"
          sx={{
            width: { xs: "120px", sm: "130px", md: "150px" },
            borderRadius: "10px",
            backgroundColor: "#5F5AFE",
          }}
          variant="contained"
          style={{ padding: "6px" }}
          loading={wareHouse?.isUpdateFetching}
          onClick={() => props?.onClick()}
        >
          {" "}
          <h1 className="text-[#ffff] text-sm xl:text-base font-medium">
            {wareHouse?.isFetching ? (
              <Spin
                className="ml-5 text-white"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              "Add To Cart"
            )}
          </h1>
        </LoadingButton>
      </div>
    </div>
  );
};

export default ProductCard;
