import React from 'react'
interface cardProps {
  icon: any,
  text: any,
  amount: any,
  yesterdayAmount: any
}
const OverviewCard = ({ icon, text, amount, yesterdayAmount }: cardProps) => {
  return (
    <div className='bg-white rounded-md p-3 px-8 shadow-md'>
      <div>
        {icon}
      </div>
      <p className='text-[#030229B2] text-[15px] pt-2'>{text}</p>
      <p className='text-[#030229] text-sm pt-2'>{amount}</p>
      <p className='text-[#03022970] text-sm pt-1'>{yesterdayAmount}</p>
    </div>
  )
}

export default OverviewCard