import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "layout",
  initialState: {
    paymentMode: null,
    isNext: 0,
    isDisabledMomo: false,
    openRepaymentModel: false,
    isOpenSellingMode: false,
    isSideNavOpen: false,
    isMissingRRAcode: false,
    confirmCheckout: false,
    openCSVButton: false,
    dataToExport: null,
    isCustomerHasSubscription:false,
    openSubMenu: {},
    selectedDate: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
    activeSubMenu: [0, 0],
    selectedInvoiceToPay: null,
    amountToPay: 0,
  },
  reducers: {
    setPaymentMode(state, action) {
      state.paymentMode = action.payload;
    },
    setIsNext(state, action) {
      state.isNext = action.payload;
    },
    setDisabledMomo(state, action) {
      state.isDisabledMomo = action.payload;
    },
    setIsMissingRRAcode(state, action) {
      state.isMissingRRAcode = action.payload;
    },
    setActiveSubMenu(state, action) {
      state.activeSubMenu = action.payload;
    },
    setSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    setSetConfirmCheckout(state, action) {
      state.confirmCheckout = action.payload;
    },
    setOpenSubMenu(state, action) {
      state.openSubMenu = action.payload;
    },
    setOpenSellingMode(state, action) {
      state.isOpenSellingMode = action.payload;
    },
    setIsSideNavOpen(state, action) {
      state.isSideNavOpen = action.payload;
    },
    setIsCustomerHasSubscription(state, action) {
      state.isCustomerHasSubscription = action.payload;
    },
    setOpenRepaymentModel(state, action) {
      state.openRepaymentModel = action.payload;
    },
    setSelectedInvoiceToPay(state, action) {
      state.selectedInvoiceToPay = action.payload;
    },
    setAmountToPay(state, action) {
      state.amountToPay = action.payload;
    },
    setOpenCSVButton(state, action) {
      state.openCSVButton = action.payload;
    },
    setDataToExport(state, action) {
      state.dataToExport = action.payload;
    },
  },
});

export const myLayoutActions = mySlice.actions;

export default mySlice.reducer;
