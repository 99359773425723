export const CurrentStockHeaders = [
  { label: "Model", key: "model" },
  { label: "Specification", key: "specification" },
  { label: "Stock In", key: "stockIn" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "tranfer" },
];
export const Freelancers_StockHeaders = [
  { label: "Model", key: "model" },
  { label: "Freelancer", key: "freelancer" },
  { label: "Specification", key: "specification" },
  { label: "Available", key: "available" },
  { label: "Pending", key: "pending" },
  { label: "Sold", key: "sold" },
  { label: "Transfer", key: "transfer" },
];
export const OneFreelancers_StockHeaders = [
  { label: "Model", key: "model" },
  { label: "Shop", key: "shop" },
  { label: "Specification", key: "specification" },
  { label: "Price", key: "price" },
  { label: "Imei", key: "serialNumber" },
  { label: "Extended Warranty", key: "extendedWarranty" },
  { label: "Status", key: "status" },
];
