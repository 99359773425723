import React, { useEffect, useState } from "react";
import {
  Input,
  Select,
  Drawer,
  message,
  Button,
  Form,
  notification,
  Table,
  Tag,
  Modal,
} from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { ReactComponent as SearchItemImage } from "../assets/images/Scan to sell Option 2.svg";
import "../pages/dashboard/Inventory/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateTeamLeadTransfersActions,
  getSearchByEmiesActions,
  getTeamLeadActions,
  getTeamLeadTransfersActions,
} from "../store/inventory/actions";
import { getActiveShop } from "../utils/converter";
import BarcodeScanner from "./BarCodeScanner";
import SearchBar from "./SearchBar";
import QRDrawer from "./QRDrawer";
import { getAgentsAssignedToShop } from "../store/auth/authActions";
import type { TableColumnsType } from "antd";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const SearchProductModal = (props: any) => {
  const { auth, appSettings, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [searchedProducts, setSearchedProducts] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [qrCodeData, setQrCodeData] = useState<any>([]);
  const [scannedCode, setScannedCode] = useState<string>();
  const [selectedTeamLeadId, setSelectedTeamLeadId] = useState<any>(null);
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [isQrDrawerVisible, setIsQrDrawerVisible] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [searchResultsData, setSearchResultsData] = useState<any>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [form] = Form.useForm();
  const [emiData, setEmiData] = useState<any>([]);
  const [teamLeadForm] = Form.useForm();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    if (isSearching && !inventory.isFetching) {
      if (emiData.wareHouseItem) {
        const existingItem = searchedProducts.some(
          (product) =>
            product?.wareHouseItem?.serialNumber ===
            emiData?.wareHouseItem?.serialNumber
        );

        if (!existingItem) {
          setSearchedProducts((prev) => [...prev, emiData]);
        }
      }
      setShowSearchResults(true);
      setIsSearching(false);
    }
  }, [props?.emiResult, isSearching]);

  const handleTeamLeadChange = (value: any) => {
    setSelectedTeamLeadId(value);
  };

  const clearSearchData = () => {
    setSearchedProducts([]);
    setSelectedTeamLeadId(null);
    setSearchValue("");
    setScannedCode(undefined);
    setNewSearch(false);
    setShowSearchResults(false);
    setSearchResultsData(null);
    setIsSearching(false);
    setEmiData([]);
    setVisible(false);
    setQrCodeData([]);
    setIsQrDrawerVisible(false);
    form.resetFields();
    teamLeadForm.resetFields();
  };

  const handleGenerateQRCode = async () => {
    if (auth.token) {
      try {
        const items = emiData.map((product: any) => ({
          item: product.wareHouseItem?._id,
          serialNumber: product.wareHouseItem?.serialNumber,
        }));

       const res=  await CreateTeamLeadTransfersActions(auth.token, {
          assignee: selectedTeamLeadId,
          list: items,
          shop: shopId,
          createdBy: auth?.user?._id,
        })(dispatch);

        if (res?.status === 201) {
          getTeamLeadTransfersActions(
            auth?.token,
            `?shop=${shopId}${props?.status}`
          )(dispatch);
        }

        setIsQrDrawerVisible(true);
        setQrCodeData(selectedTeamLeadId);
        setVisible(true);
        props?.setIsModalOpen(false);
        clearSearchData();
        
      } catch (error) {
        notification.error({ message: "Error during transfer" });
      }
    }
  };

  useEffect(() => {
    auth?.token &&
      getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
        ?.shop?._id &&
      getAgentsAssignedToShop(
        auth?.token,
        getActiveShop(auth?.user?.shop?.assigned)[
          appSettings?.selectedShopNumber
        ]?.shop?._id
      )(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth?.token,
    auth?.user?.shop,
    dispatch,
  ]);

  const getCode = (code: any) => {
    setScannedCode(code);
    handleSearch(code);
  };

  useEffect(() => {
    if (auth?.token) {
      getTeamLeadActions(auth?.token)(dispatch);
    }
  }, [auth?.token, dispatch]);

  const handleSearch = async (values: any) => {
    setIsSearching(true);
    const res: any = await getSearchByEmiesActions(
      auth?.token,
      `?serialNumber=${values?.query}&shopId=${shopId}`
    )(dispatch);

    if (res.data.product === "sold") {
      notification.error({
        message: "The product is sold.",
      });
    }

    if (res?.type && res?.data?.product) {
      const isDuplicate = emiData.some(
        (item: any) =>
          item?.wareHouseItem?._id === res.data.product?.wareHouseItem?._id
      );

      if (!isDuplicate) {
        setEmiData((prevData: any) => [...prevData, res.data.product]);
        form.resetFields();
      } else {
        message.warning("This device is already in the list");
      }
    }
  };

  const handleRemoveDevice = (deviceId: any) => {
    setEmiData((prevData: any) =>
      prevData.filter((item: any) => item?.wareHouseItem?._id !== deviceId)
    );
    message.success("Device removed successfully");
  };

  const handleChange = () => {
    setNewSearch(true);
  };

  const handleDiscard = () => {
    clearSearchData();
    message.success("All data has been discarded");
    if (props?.setIsModalOpen) {
      props.setIsModalOpen(false);
    }
  };

  const handleDiscardWithConfirmation = () => {
    if (emiData.length > 0) {
      Modal.confirm({
        title: "Confirm Discard",
        content:
          "Are you sure you want to discard all items? This action cannot be undone.",
        okText: "Yes, Discard All",
        cancelText: "No, Keep Items",
        okButtonProps: {
          danger: true,
        },
        onOk: handleDiscard,
      });
    } else {
      handleDiscard();
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Model",
      dataIndex: "model",
      render: (text: any) => {
        return (
          <>
            <div className="px-4">
              <p className="text-xs sm:text-base">{text}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: any) => {
        return (
          <>
            {text?.map((spec: any, index: number) => (
              <div
                key={index}
                className="flex flex-row gap-3 sm:gap-3 text-xs sm:text-base"
              >
                <span className="text-xs sm:text-base">{spec[0]}:</span>{" "}
                <span className="text-xs sm:text-base">{spec[1]}</span>
              </div>
            ))}
          </>
        );
      },
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      render: (text: any) => {
        return (
          <>
            <div className="px-4">
              <p className="text-xs sm:text-xs">{text}</p>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "data",
      render: (text: any) => {
        return (
          <>
            <div className="px-4">
              <Button
                type="link"
                danger
                onClick={() => handleRemoveDevice(text?._id)}
              >
                <DeleteFilled />
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const data = emiData.map((item: any) => {
    return {
      key: item?.wareHouseItem?._id,
      model: item?.requestedItem?.product?.product?.model,
      description: item?.requestedItem?.product?.specification,
      imei: item?.wareHouseItem?.serialNumber,
      data: item?.wareHouseItem,
    };
  });

  return (
    <div className="flex flex-col items-center md:px-3 py-4">
      <div className="flex items-center gap-3 h-[50px]">
        <Form form={form} onFinish={handleSearch} className="flex-grow">
          <Form.Item name="query" initialValue={scannedCode} className="w-full">
            <SearchBar
              isLoading={inventory?.isFetching}
              scannedCode={newSearch ? "" : scannedCode}
              onChange={handleChange}
            />
          </Form.Item>
        </Form>
        <div className="flex items-center j-pointer whitespace-nowrap h-full px-4 mt-2">
          <BarcodeScanner getCode={getCode} />
        </div>
      </div>

      {emiData.length === 0 ? (
        <SearchItemImage />
      ) : (
        <>
          <div className="w-[100%] mb-6 pxh-4 pt-4">
            <div className="rounded-lg w-full border border-gray-200 overflow-x-auto">
              <Table<DataType>
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
              />
            </div>
          </div>

          <div className="w-full sm:w-[500px] px-4">
            <div className="mb-6">
              <Form form={teamLeadForm}>
                <Form.Item
                  label={
                    <span className="block text-xs sm:text-sm font-medium mb-1">
                      Assignee to
                    </span>
                  }
                  name="teamLead"
                >
                  <Select
                    placeholder="Select assignee"
                    className="w-full sm:w-[419px]"
                    loading={inventory.isFetching}
                    options={auth?.agents
                      .filter(
                        (item: any) => item?.user?.profile === "freelancer"
                      )
                      .flatMap((item: any) => ({
                        value: item?.user?._id,
                        label: item?.user?.names,
                      }))}
                    onChange={handleTeamLeadChange}
                    value={selectedTeamLeadId}
                  />
                </Form.Item>
              </Form>
            </div>

            <div className="flex flex-col sm:flex-row justify-between gap-3 pt-8">
              <Button
                className="px-6 py-2 border border-gray-300 rounded-md w-full sm:w-[300px] text-[#605BFF] flex items-center justify-center"
                onClick={handleDiscardWithConfirmation}
                disabled={emiData.length === 0}
              >
                Discard All
              </Button>

              <Button
                className="px-6 py-2 bg-[#605BFF] text-white rounded-md w-full sm:w-[300px] flex items-center justify-center"
                onClick={handleGenerateQRCode}
                disabled={inventory.isFetching || !selectedTeamLeadId}
              >
                Confirm Transfer to Assignee
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SearchProductModal;
