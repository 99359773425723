import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import TourOne from "../../../../../assets/images/insertSim.png";
import TourTwo from "../../../../../assets/images/settingUp.png";
import TourThree from "../../../../../assets/images/restricted.jpg";
import TourFour from "../../../../../assets/images/accept.jpg";
import {
  handlecheckStateInfoAction,
  handleConfirmFinalStageAction,
} from "../../redux/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import { notification, Progress, Steps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getDeviceInfoAction } from "../../redux/vault/actions";
import { checkPaymentAction } from "../../../../../store/pos/actions";
import { updateShopStockCartDetailsAction } from "../../../../../store/wareHouse/actions";

const LockingTour = () => {
  const { auth, wareHouse, subLayout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [vaultStatus, setVaultStatus] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const { cartId } = useParams();
  const imei =
    wareHouse?.createdCart?.data?.list?.at(0)?.warehouseProduct?.serialNumber;
  const steps = [
    {
      title: "",
      content: (
        <div className="flex mx-auto items-center justify-center flex-col">
          <img src={TourOne} alt="" />
          <div className="flex items-center justify-center flex-col">
            <h1 className="font-bold text-base">Insert SIM Card</h1>
            <p className="text-sm">
              Open the box, open device cover and insert sim card then turn on
              device
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "",
      content: (
        <div className="flex mx-auto items-center justify-center flex-col">
          <img src={TourTwo} alt="" />
          <div className="flex items-center justify-center flex-col">
            <h1 className="font-bold text-base">Setting Up Device</h1>
            <p className="text-sm">
              Press start button, connect device to mobile network for setup.
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "",
      content: (
        <div className="flex mx-auto items-center justify-center flex-col">
          <img src={TourThree} alt="" />
          <div className="flex items-center justify-center flex-col">
            <h1 className="font-bold text-base">Enable Vault</h1>
            <p className="text-sm">Configure the device into vault system.</p>
          </div>
        </div>
      ),
    },
    {
      title: "",
      content: (
        <>
          <div className="flex gap-x-4 items-center my-5">
            <Progress
              type="circle"
              percent={
                ["Ready For Use"]?.includes(subLayout?.stateInfoStatus)
                  ? 50
                  : ["Active"]?.includes(subLayout?.stateInfoStatus)
                  ? 100
                  : 0
              }
              size={40}
              status={
                ["Idle"]?.includes(subLayout?.stateInfoStatus)
                  ? "exception"
                  : undefined
              }
            />
            <span>
              {["Active"]?.includes(subLayout?.stateInfoStatus)
                ? "Vault Activated Successfuly"
                : "Activate Vaults"}
            </span>
          </div>
          <div className="flex mx-auto items-center justify-center flex-col">
            <img src={TourFour} alt="" />
            <div className="flex items-center justify-center flex-col">
              <h1 className="font-bold text-base">Done</h1>
              <p className="text-sm">Accept to finish Configure </p>
            </div>
          </div>
          {["Ready For Use", "Idle"]?.includes(subLayout?.stateInfoStatus) &&
            vaultStatus && (
              <div className="text-red-400 text-sm py-2 text-center">
                Vault System Inprogress Please Reload Device
              </div>
            )}
          {["Idle"]?.includes(subLayout?.stateInfoStatus) && (
            <div className="text-red-400 text-base py-2 text-center">
              Vault System Failed Please Contact for supervisor Support or
              Realod Device
            </div>
          )}
        </>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const handleConfirm = async () => {
    setIsLoading(true);
    const res = await getDeviceInfoAction(auth?.token, {
      deviceUid: [imei],
    })(dispatch);
    if (res?.type) {
      setIsLoading(false);
      if (res?.data?.deviceResponseList[0]?.stateInfo === "Ready For Use") {
        setVaultStatus(true);
        await handlecheckStateInfoAction("Ready For Use")(dispatch);
      } else if (res?.data?.deviceResponseList[0]?.stateInfo === "Active") {
        setVaultStatus(false);
        await handlecheckStateInfoAction("Active")(dispatch);
        await updateShopStockCartDetailsAction(auth?.token, cartId, {
          subscriptionStatus: "VAULT_ACTIVATED",
        })(dispatch);
        notification.success({
          message: "The device has been activated.",
        });
      }
    } else {
      setIsLoading(false);
      notification.success({
        message: "Please reload since the device has not yet activated!!",
      });
    }
  };
  const checkPayment = async () => {
    setIsLoading(true);
    if (auth.token) {
      const res = await checkPaymentAction(auth?.token, cartId as string, {
        status: "paid",
      })(dispatch);
      if (res) {
        setIsLoading(false);
        handleConfirmFinalStageAction(true)(dispatch);
        // const res = await createSalesActions(auth?.token, {
        //   cartId: cartId as string,
        //   salesTypeCode: "N",
        //   receiptTypeCode: "S",
        //   paymentTypeCode: "01",
        //   salesStatusCode: "02",
        //   // "purchaserOrderCode": "315963"
        // })(dispatch);
        // if (res) {
        //   updateShopStockCartDetailsAction(auth?.token, cartId, {
        //     subscriptionStatus: "BILL_ACTIVATED",
        //   })(dispatch);
        // }
      } else {
        setIsLoading(false);
      }
    } else {
      return notification.error({
        message: "Error",
        description: "You are not logged in",
      });
    }
  };
  const showConfirmation = async () => {
    handleConfirmFinalStageAction(true)(dispatch);
    // await checkPayment();
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      {/* <div className="mt-5"> */}
      <Steps current={current} items={items} />
      {/* </div> */}
      <div>{steps[current].content}</div>
      <div
        style={{ marginTop: 24 }}
        className="flex items-center justify-center  gap-x-2"
      >
        <div>
          {current > 0 && (
            <LoadingButton
              variant="outlined"
              onClick={() => prev()}
              sx={{
                width: { xs: "120px", sm: "130px", md: "150px" },
                borderRadius: "5px",
              }}
            >
              Previous
            </LoadingButton>
          )}
        </div>
        <div>
          {current < steps.length - 1 && (
            <LoadingButton
              variant="contained"
              onClick={() => next()}
              sx={{
                width: { xs: "120px", sm: "130px", md: "150px" },
                borderRadius: "5px",
              }}
            >
              Next
              {/* ["Idle"]?.includes(subLayout?.stateInfoStatus) */}
            </LoadingButton>
          )}
          {current === steps.length - 1 && (
            <LoadingButton
              variant="contained"
              loading={
                subLayout?.isFetching || loading || wareHouse?.isFetching
              }
              // onClick={showConfirmation}
              onClick={
                ["Active"]?.includes(subLayout?.stateInfoStatus)
                  ? showConfirmation
                  : handleConfirm
              }
              sx={{ width: 150 }}
            >
              {["Active"]?.includes(subLayout?.stateInfoStatus)
                ? "Done"
                : "Reload"}
            </LoadingButton>
          )}
        </div>
      </div>
    </>
  );
};

export default LockingTour;
