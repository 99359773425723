import { myCSVAction } from ".";
import { getStockForOwnAssigneeServices, getStockStatusSCountServices } from "../inventory/services";
import { myLayoutActions } from "../layout";
import { getAllServiceStock } from "../pos/services";

export const getStock_CSVAction = (
  token: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getAllServiceStock(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
export const getFreelancerStock_CSVAction = (
  token: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getStockStatusSCountServices(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};
export const getOneFreelancerStock_CSVAction = (
  token: any,
  query?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCSVAction.setIsFetching(true));
      const res = await getStockForOwnAssigneeServices(token, query);
      if (res?.status === 200) {
        dispatch(myCSVAction.setCSVDownloadedData(res));
        dispatch(myCSVAction.setAllowCSVDownload(true));
        dispatch(myCSVAction.setIsFetching(false));
      } else {
        dispatch(myLayoutActions.setOpenCSVButton(false));
      }
      dispatch(myCSVAction.setIsFetching(false));
    } catch (err) {
      console.log(err);
      dispatch(myCSVAction.setIsFetching(false));
    }
  };
};

