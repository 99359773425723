import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Radio,
  Drawer,
  Button,
  Table,
  Typography,
  Tag,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { GoPlus } from "react-icons/go";
import { getActiveShop } from "../../../utils/converter";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import {
  getTeamLeadstatusCountActions,
  getTeamLeadTransfersActions,
  getTeamLeadTransfersDetailsActions,
} from "../../../store/inventory/actions";
import CustomButton from "../../../components/buttons/CustomButton";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import TeamLeadGridCard from "../../../components/grids/TeamLeadGridCard";
import SearchProductModal from "../../../components/SearchProductModal";
import TransferCard from "../../../components/cards/TransferCard";
import ScanQRCode from "../../../components/ScanQRCode";
import QRDrawer from "../../../components/QRDrawer";
import SearchProductTobeReturnedModal from "../../../components/SearchProductTobeReturnedModal";
import { TransferCardSkeleton } from "../../../components/skeleton/Card";

const TeamleadTransfer = () => {
  const { inventory, appSettings, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [statusFilter, setStatusFilter] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState<any>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isQrDrawerOpen, setIsQrDrawerOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(15);
  const [qrCodeData, setQrCodeData] = useState<any>(null);
  const [getTransferId, setGeTransferId] = useState<any>(null);
  const [selectedTransferId, setSelectedTransferId] = useState<any>(null);

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;


  const statusOptions = [
    {
      label: `All Transfers(${(inventory?.statusCount && inventory?.statusCount?.data?.total) || 0
        } )`,
      value: "",
    },
    {
      label: `Pending(${(inventory?.statusCount && inventory?.statusCount?.data?.pending) || 0
        })`,
      value: "pending",
    },
    {
      label: `Cancelled(${(inventory?.statusCount && inventory?.statusCount?.data?.cancelled) || 0
        })`,
      value: "cancelled",
    },
    {
      label: `Active(${(inventory?.statusCount && inventory?.statusCount?.data?.active) || 0
        })`,
      value: "active",
    },
    {
      label: `Closed(${(inventory?.statusCount && inventory?.statusCount?.data?.closed) || 0
        })`,
      value: "closed",
    },
  ];

  useEffect(() => {
    if (auth?.user?.profile?.toLowerCase() === "freelancer") {
      auth.token &&
        getTeamLeadstatusCountActions(
          auth.token,
          `${shopId}?assignee=${auth?.user?._id}`
        )(dispatch);
    } else {
      auth.token &&
        getTeamLeadstatusCountActions(auth?.token, `${shopId}`)(dispatch);
    }
  }, [auth?.token, dispatch, shopId]);

  const fetchTransfers = (status: string) => {
    if (auth?.token) {
      if (auth?.user?.profile?.toLowerCase() === "freelancer") {
        getTeamLeadTransfersActions(
          auth.token,
          `?shop=${shopId}&assignee=${auth?.user?._id}&status=${status}`
        )(dispatch);
      } else {
        getTeamLeadTransfersActions(
          auth.token,
          `?shop=${shopId}&status=${status}`
        )(dispatch);
      }
    }
  };

  useEffect(() => {
    fetchTransfers(statusFilter);
  }, [auth?.token, dispatch, page, limit, statusFilter]);

  const handleStatusChange = (e: any) => {
    const { value } = e.target;
    setStatusFilter(value);
    fetchTransfers(value);
  };

  const closeQrDrawer = () => {
    setIsQrDrawerOpen(false);
  };
  const getStatusColor = (status: any) => {
    switch (status) {
      case "Pending":
        return "#BDBDBD";
      case "Cancelled":
        return "#EB4343";
      case "active":
        return "#3AC722";
      default:
        return "#BDBDBD";
    }
  };

  const tableColumns = [
    {
      title: "Model",
      dataIndex: ["item", "product", "model"],
      key: "model",
    },
    {
      title: "Specifications",
      dataIndex: ["item", "product", "specs"],
      key: "specs",
      render: (specs: any) => (
        <div className="space-y-1">
          {specs?.map((spec: any) => (
            <div key={spec?._id}>
              {spec?.label}: {spec?.value?.join(", ")}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "IMEI",
      dataIndex: ["item", "serialNumber"],
      key: "serialNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => (
        <Tag
          color={getStatusColor(status)}
          className="capitalize min-w-[50px] text-center"
        >
          {status}
        </Tag>
      ),
    },
  ];

  const handleQrDrawerOpen = async (value: any) => {
    setIsDrawerOpen(false);
    setIsQrDrawerOpen(true);
    setQrCodeData(value);
  };

  const handleReturnDeviceModal = async () => {
    setIsReturnModalOpen(true);
  };

  useEffect(() => {
    if (auth.token && selectedTransferId) {
      try {
        getTeamLeadTransfersDetailsActions(
          auth.token,
          selectedTransferId
        )(dispatch);
      } catch (error) {
        console.error("Error fetching transfer details:", error);
      }
    }
  }, [auth.token, selectedTransferId, dispatch]);

  const handleCardClick = (transfer: any) => {
    setSelectedTransferId(transfer?._id);
    setSelectedTransfer(transfer);
    setIsDrawerOpen(true);
  };

  const handleCreatTransferModal = async () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => setIsModalOpen(false);
  const handleDrawerClose = () => setIsDrawerOpen(false);
  const handleReturnModalClose = () => setIsReturnModalOpen(false);

  const emiResult = inventory?.searchByEmies?.data;

  const dataSource = inventory?.transferDetails?.data?.list || [];
  return (
    <div className="text-[#030229]">
      <div className="md:flex justify-between items-center pb-4">
        <div className="w-full overflow-x-auto pb-2">
          <div className="min-w-fit p-2">
            <Radio.Group
              options={statusOptions}
              onChange={handleStatusChange}
              value={statusFilter}
              optionType="button"
              buttonStyle="solid"
              className="flex flex-wrap text-xs md:text-base whitespace-nowrap gap-1 md:gap-2"
            />
          </div>
        </div>
        {auth?.user?.role?.toLowerCase() === "admin" && (
          <CustomButton
            title={"Transfer"}
            bgColor={"[#605BFF]"}
            textColor="white"
            onClick={handleCreatTransferModal}
            icon={<GoPlus size={20} color="white" />}
          />
        )}
        {auth?.user?.profile === "team-leader" && (
          <CustomButton
            title={"Transfer"}
            bgColor={"[#605BFF]"}
            textColor="white"
            onClick={handleCreatTransferModal}
            icon={<GoPlus size={20} color="white" />}
          />
        )}
      </div>

      <Modal
        title={
          <h1 className="text-sm sm:text-base md:text-xl font-semibold">
            Create Transfer
          </h1>
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={942}
        centered
        bodyStyle={{ height: "579px", overflowY: "auto" }}
      >
        <SearchProductModal
          setIsModalOpen={setIsModalOpen}
          status={statusFilter}
          selectedTransferId={selectedTransferId}
          emiResult={emiResult}
          getTransferId={getTransferId}
        />
      </Modal>
      <Modal
        title={<h1 className="text-xl font-semibold">Return Device</h1>}
        open={isReturnModalOpen}
        onCancel={handleReturnModalClose}
        footer={null}
        width={942}
        centered
        bodyStyle={{ height: "579px", overflowY: "auto" }}
      >
        <SearchProductTobeReturnedModal
          setIsModalOpen={setIsModalOpen}
          status={statusFilter}
          selectedTransferId={selectedTransferId}
          emiResult={emiResult}
          getTransferId={getTransferId}
          setIsReturnModalOpen={setIsReturnModalOpen}
        />
      </Modal>

      <ScrollableFrame
        loading={inventory?.isFetching}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={inventory?.all?.total}
        count={Math.ceil(inventory?.all?.total / limit)}
      >
        {inventory.isFetching ? (
          <div className="grid md:grid-cols-2 2xl:grid-cols-4 gap-6">
            {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
              <TransferCardSkeleton />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-6">
            {inventory?.transfer?.data?.map((transfer: any) => (
              <TeamLeadGridCard
                key={transfer._id}
                transfer={transfer}
                onClick={() => handleCardClick(transfer)}
              />
            ))}
          </div>
        )}
      </ScrollableFrame>

      <Drawer
        placement="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        width={600}
        title={
          <div className="flex justify-between">
            <span> Transfer Details</span>
            <Button
              onClick={handleDrawerClose}
              icon={<CloseOutlined />}
              type="text"
            />
          </div>
        }
        closable={false}
      >
        {selectedTransfer && (
          <div className="p-4 border rounded-lg">
            <div>
              <div className="flex flex-row justify-between items-center">
                <div className="text-[#030229] text-[16px]">
                  {selectedTransfer?.transferId}
                </div>
                <div>
                  {["team-leader"].includes(auth?.user?.profile) && (
                    <div
                      onClick={() =>
                        handleQrDrawerOpen(
                          inventory?.transferDetails?.data?.assignee?._id
                        )
                      }
                      className="text-[#605BFF] p-1.5 bg-white rounded-md border border-[#a5a3e2] cursor-pointer text-[13px]"
                    >
                      View QR Code
                    </div>
                  )}{" "}
                  {["team-leader"].includes(auth?.user?.profile) &&
                    ["active"].includes(
                      inventory?.transferDetails?.data?.status
                    ) && (
                      <div
                        onClick={handleReturnDeviceModal}
                        className="text-[#605BFF] p-1.5 bg-white mt-3 rounded-md border border-[#a5a3e2] cursor-pointer text-[13px]"
                      >
                        Return Device
                      </div>
                    )}
                  {inventory?.transferDetails?.data.status === "pending" && (
                    <>
                      {["freelancer"].includes(auth?.user?.profile) && (
                        <div className="py-4">
                          <ScanQRCode
                            currentTeamLeadId={
                              inventory?.transferDetails?.data?.assignee?._id
                            }
                            transferId={selectedTransfer?._id}
                            status={statusFilter}
                            shopId={shopId}
                            setIsDrawerOpen={setIsDrawerOpen}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <Typography.Link href="#" className="text-[13px]">
                {selectedTransfer?.shopName}
              </Typography.Link>
            </div>

            <Typography.Text>
              <span className="text-gray-500 text-[13px] py-3">
                Created By:
              </span>
              <span className="text-[13px]">
                {" "}
                {selectedTransfer?.createdBy}
              </span>
            </Typography.Text>
            <br />
            <Typography.Text>
              <span className="text-gray-500 text-[13px] py-3">Assignee:</span>{" "}
              <span className="text-[13px]"> {selectedTransfer?.assignee}</span>
            </Typography.Text>
            <br />
            <Typography.Text>
              <span className="text-gray-500 text-[13px] py-3">createdOn:</span>{" "}
              <span className="text-[13px]">
                {" "}
                {selectedTransfer?.createdOn?.slice(0, 10)}
              </span>
            </Typography.Text>
          </div>
        )}
        {selectedTransfer && (
          <div className="flex flex-col md:flex-row gap-3 pt-3">
            <TransferCard
              total={`${inventory?.transferDetails?.data?.itemsCounts?.totalItems || 0
                }`}
              text="Total Devices"
              icon={<BoxIcons />}
            />
            <TransferCard
              total={`${inventory?.transferDetails?.data?.itemsCounts?.pending || 0
                }`}
              text="Total pending"
              icon={<BoxIcons />}
            />
            <TransferCard
              total={`${inventory?.transferDetails?.data?.itemsCounts?.available || 0
                }`}
              text="Total Available"
              icon={<BoxIcons />}
            />
          </div>
        )}

        {selectedTransfer && (
          <div className="mt-4">
            <h2 className="text-xl">Devices</h2>
            <Table
              columns={tableColumns}
              dataSource={dataSource}
              rowKey="imei"
              loading={inventory.isFetching}
              scroll={{ x: "100%", y: 300 }} // Adjust the 'y' value as needed for vertical scroll
            />
          </div>
        )}
      </Drawer>
      <QRDrawer
        isOpen={isQrDrawerOpen}
        onClose={closeQrDrawer}
        qrData={selectedTransferId}
      />
    </div>
  );
};
export default TeamleadTransfer;
