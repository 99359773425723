import React, { useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Spin } from "antd";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import { TbGridDots } from "react-icons/tb";
import { GoListUnordered, GoPlus } from "react-icons/go";
import StockTransferTable from "../../../components/tables/StockTransferTable";
import { getActiveShop } from "../../../utils/converter";
import { overViewStatActions } from "../../../store/posOverView/actions";
import CustomButton from "../../../components/buttons/CustomButton";
import SearchProductModal from "../../../components/SearchProductModal";
import TransferToWarehouse from "../../../components/TransferToWarehouse";
import { createRequestStockAction } from "../../../store/channel/actions";
import { useNavigate } from "react-router-dom";

const StockTransfer = () => {
  const { posOverView, appSettings, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [value4, setValue4] = useState("pending");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    setValue4(value);
  };
  const navigate = useNavigate();
  const options = [
    { label: "Pending Stock ", value: "pending" },
    // { label: 'Approved Stock ', value: 'request', title: 'done' },
    { label: "In Transit Stock ", value: "In-Transit" },
    // { label: 'Review ', value: 'review'},
    
  ];

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  React.useEffect(() => {
    auth?.token && overViewStatActions(auth?.token, shopId)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  const handleCreatTransferModal = async () => {
    setIsModalOpen(true);
  };

  const handleModalClose = async () => {
    setIsModalOpen(false);
  };

  return (
    <div className="text-black">
      <h1 className="text-[#030229d8] text-sm sm:text-base  md:text-lg xl:text-xl">
        Stock Transfer
      </h1>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-3">
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.stockRequests || 0
            )
          }
          text="Total Requested"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalCurrentDevices || 0
            )
          }
          text="Total Stock"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalDevicesSold || 0
            )
          }
          text="Total Sold"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.transfer || 0
            )
          }
          text="Total Transfer"
          icon={<BoxIcons />}
        />
      </div>
      <div className="mt-5 md:flex justify-between">
        <Radio.Group
          options={options}
          onChange={onChange4}
          value={value4}
          optionType="button"
          buttonStyle="solid"
        />
        <div className="flex gap-5 mt-5 sm:mt-0">
          
          <CustomButton
            title={"Transfer To Warehouse"}
            bgColor={"[#605BFF]"}
            textColor="white"
            onClick={async () => {
              if (auth?.token) {
                const res: any = await createRequestStockAction(auth?.token, {
                  shopId: shopId,
                  channel: auth?.user?.shop?.channel,
                })(dispatch);
                if (res) {
                  navigate(`/pos/transfer/${res?.requestId}`);
                }
              }
          
            }}
            icon={<GoPlus size={20} color="white" />}
          />
        </div>
      </div>
      <Modal
        title={
          <h1 className="text-sm sm:text-base md:text-xl font-semibold">
            Transfer To warehouse
          </h1>
        }
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        width={942}
        centered
        bodyStyle={{ height: "579px", overflowY: "auto" }}
      >
        <TransferToWarehouse
          setIsModalOpen={setIsModalOpen}
        
        />
      </Modal>
      <div className="mt-5">
        <StockTransferTable selectedValueButton={value4} />
      </div>
    </div>
  );
};

export default StockTransfer;
