import React, { useEffect, useState } from "react";
import ApplicantsCard from "../components/ApplicantsCard";
import img from "../../../assets/images/profile.jpeg";
import { Radio, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import {
  getAllApplicantsAction,
  getApplicantsDetailsAction,
} from "../redux/businessPlans/action";
import { useDispatch, useSelector } from "react-redux";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import "./style.css";
import { searchValue } from "../../../utils/setColor";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
const Applicants = () => {
  const { auth, apllicants, wareHouse } = useSelector((state: any) => state);
  const [status, setStatus] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onChange = (e: any) => {
    setStatus(e.target.value);
  };
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  useEffect(() => {
    if (status) {
      auth?.token &&
        getAllApplicantsAction(
          auth?.token,
          `?createdBy=${auth?.user?._id}&status=${status}`
        )(dispatch);
    } else {
      auth?.token &&
        getAllApplicantsAction(
          auth?.token,
          `?createdBy=${auth?.user?._id}&status[]=pending&status[]=accepted&status[]=rejected&status[]=requested&status[]=inquiry`
        )(dispatch);
    }
  }, [auth?.token, dispatch, status, limit, page]);
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  return (
    <div className="radioCustom">
      <div className="w-full overflow-x-auto">
        <Radio.Group
          defaultValue=""
          buttonStyle="solid"
          onChange={onChange}
          className="ml-5"
        >
          <Radio.Button value="">All</Radio.Button>
          <Radio.Button value="pending">Pending</Radio.Button>
          <Radio.Button value="requested">Requested</Radio.Button>
          <Radio.Button value="accepted">Accepted</Radio.Button>
        </Radio.Group>
      </div>
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={apllicants?.allApplicants?.total}
        count={Math.ceil(apllicants?.allApplicants?.total / limit)}
      >
        <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5">
          {apllicants?.allApplicants?.data?.map((el: any) => {
            const phone = el?.customer?.phone;
            const isCustomerExist =
              phone || el?.customer?.names || el?.customer?.email;
            const maskedPhone = phone
              ? `${phone.slice(0, 5)}*********${phone.slice(-2)}`
              : "";
            const NId = el?.customer?.nationalId;
            const maskedNID = NId
              ? `${NId.slice(0, 2)}*********${NId.slice(-2)}`
              : "";
            return (
              <>
                {apllicants?.isFetching || wareHouse?.isFetching ? (
                  <div className=" mt-5">
                    {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
                      <CurstomeCardSkeleton />
                    ))}
                  </div>
                ) : (
                  isCustomerExist && (
                    <div>
                      {el?.status === "accepted" ? (
                        <div
                          className="bg-white p-2 w-[c384px] rounded-md h-[182px] mt-5 "
                          style={styles.customShadow}
                          onClick={() => (
                            getApplicantsDetailsAction(
                              auth?.token,
                              el?._id
                            )(dispatch),
                            navigate(`/applicants/${el?._id}`),
                            localStorage.setItem("applicantId", el?._id)
                          )}
                        >
                          <div className="flex gap-5 w-full">
                            <div className="w-[42px] h-[42px] rounded-full border flex justify-center items-center ">
                              <img src={img} className="rounded-full" />
                            </div>
                            <div className="w-full">
                              <div className="flex justify-between w-full">
                                <p className="text-sm font-semibold text-[#030229cc]">
                                  {el?.customer?.name || null}
                                </p>
                                <Tag
                                  color={searchValue(el.status)}
                                  style={{
                                    minWidth: "4rem",
                                    textAlign: "center",
                                  }}
                                >
                                  <span
                                    className={`text-sm capitalize ${el?.status === "pending" && "text-black"
                                      }`}
                                  >
                                    {el?.status}
                                  </span>
                                </Tag>
                              </div>
                              <div className="flex w-full gap-10 mt-5">
                                <p className="text-[#03022980]  text-sm">
                                  {" "}
                                  Plan
                                </p>
                                <p className="text-[#030229] text-sm">
                                  {
                                    el?.selectedPlan?.pricelistItem?.product
                                      ?.model
                                  }
                                </p>
                              </div>
                              <div className="flex  gap-5">
                                {el?.selectedPlan?.pricelistItem?.specification?.map(
                                  (data: any, index: number) => {
                                    const propertyName = data[0].toLowerCase();

                                    if (
                                      !["type", "brand"].includes(propertyName)
                                    ) {
                                      return (
                                        <div className="flex gap-2" key={index}>
                                          <p>
                                            <span className=" text-[12px] text-[#03022980] ">{`${data[0]}: `}</span>
                                          </p>
                                          <p className="pl-1 pt-1 text-[12px] text-[#030229]">
                                            {data[1]}
                                          </p>
                                        </div>
                                      );
                                    }

                                    return null;
                                  }
                                )}
                              </div>
                              <div className="flex w-full gap-5 mt-3">
                                <p className="text-[#03022980]  text-sm">
                                  {" "}
                                  Installment
                                </p>
                                <p className="text-[#030229] text-sm">
                                  {el?.selectedPlan?.monthlyInstalment?.toLocaleString()}{" "}
                                  /Month, {el?.selectedPlan?.duration} Months
                                </p>
                              </div>
                              <div className="flex w-full gap-10 mt-3">
                                <p className="text-[#03022980]  text-sm">
                                  Phone
                                </p>
                                <p className="text-[#030229] text-sm">
                                  {maskedPhone}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <ApplicantsCard
                          name={el?.customer?.name}
                          text={maskedNID}
                          img={el?.customer?.picture}
                          email={el?.customer?.email}
                          phone={maskedPhone}
                          status={el?.status}
                          onClick={() => (
                            getApplicantsDetailsAction(
                              auth?.token,
                              el?._id
                            )(dispatch),
                            navigate(`/applicants/${el?._id}`)
                          )}
                        />
                      )}
                    </div>
                  )
                )}
              </>
            );
          })}
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default Applicants;
