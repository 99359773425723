import { Radio } from "antd";
import React from "react";

const ConfirmationQuestion: React.FC<{
  question: string;
  handleChange: any;
  questionNumber: number;
}> = ({ question, questionNumber, handleChange }) => {
  return (
    <div className="flex items-center justify-between border px-6 rounded-md py-2.5">
      <label className="text-gray-800 font-medium text-sm">{question}</label>
      <Radio.Group
        name={`radiogroup-${questionNumber}`}
        onChange={(e) => handleChange(e, questionNumber)}
        className="flex flex-col space-y-0.5"
      >
        <Radio value="true">Yes</Radio>
        <Radio value="false">No</Radio>
      </Radio.Group>
    </div>
  );
};

export default ConfirmationQuestion;
