import { myCustomerActions } from ".";
import {
  createServiceCustomer,
  getAllServiceCustomer,
  updateServiceCustomer,
  getOneSelectedService,
  getOneCustomerByQueryService,
  updateIsDeleteService,
  getCustomerPuchased,
  getAllRepairService,
  getTopServiceCustomer,
  getAnalyticsServiceCustomer,
  getServiceCustomerOverview,
  getStatusCustomerService,
  getRecentCustomerService,
  getCustomerInvoiceServices,
  getBellingInvoiceServices,
  getCustomerNotesServices,
  getCustomerNoSaleServices,
  getCustomerMetricsServices,
  checkCustomerLoanService,
  getCustomerLoanService,
  getOneCustomerByPhoneService,
} from "./services";
import { SessionExpired } from "../auth/AutoLogout";
import { notification } from "antd";

export const createCustomerAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));

      const res = await createServiceCustomer(data, token);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myCustomerActions.setNew(res));
        dispatch(myCustomerActions.setIsFetching(false));
        notification.success({
          message: "Created Successfully!",
        });
      } else if (res?.response?.status === 409) {
        console.log("In Here::::::::::");
        dispatch(myCustomerActions.setIsFetching(false));
        notification.warning({
          message: "Customer with this phone number already exists!",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCustomersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getAllServiceCustomer(token);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setAll(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRecentCustomersAction = (token: string, shopId?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getRecentCustomerService(token, shopId);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setRecentcustomer(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomersStatusAction = (token: string, shopId?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getStatusCustomerService(token, shopId);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setcustomerStatus(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomersOverviewAction = (token: string, shopId?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getServiceCustomerOverview(token, shopId);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerOverview(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAnalyticAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getAnalyticsServiceCustomer(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setAnalyticsCustomers(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getTopCustomersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getTopServiceCustomer(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setTopCustomers(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneSelectedService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelected(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerByQueryAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneCustomerByQueryService(query, token);
      if (!res?.data?.length)
        notification.warning({ message: "Customer not found !" });
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSearchResult(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCustomerAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));

      const res = await updateServiceCustomer(itemId, data, token);

      if (res?.status === 200) {
        dispatch(myCustomerActions.setUpdated(res));
        dispatch(myCustomerActions.setNew(res));
        dispatch(myCustomerActions.setIsFetching(false));
        return true;
      }
      // For Anauthorized Error
      if (res.name === "AxiosError") {
        dispatch(myCustomerActions.setIsFetching(false));
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
      dispatch(myCustomerActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteCustomerAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await updateIsDeleteService(itemId, data, token);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelected(res?.data));
        const resAll = await getAllServiceCustomer(token, "");
        dispatch(myCustomerActions.setAll(resAll));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const getCustomerPurchaseAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerPuchased(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setPurchased(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCustomerRepairsAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getAllRepairService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerRepair(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerInvoiceAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerInvoiceServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerInvoice(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getBellingInvoiceAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getBellingInvoiceServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setBellingInvoice(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerNotesAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerNotesServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerNotes(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerNoSaleAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerNoSaleServices(token, query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerNoSale(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getCustomerMetricsAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerMetricsServices(id, token);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerMetrics(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const checkCustomerLoanAction = (id: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await checkCustomerLoanService(id, data);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerLoan(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
        return true;
      }
      if (res?.response?.status === 400) {
        notification.error({ message: res?.response?.data?.error });
        dispatch(myCustomerActions.setIsFetching(false));
        return false;
      }
      dispatch(myCustomerActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCustomerLoanAction = (itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsGettingCustomerLoan(true));
      const res = await getCustomerLoanService(itemId);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerLoan(res?.data));
        dispatch(myCustomerActions.setIsGettingCustomerLoan(false));
        return true;
      }
      dispatch(myCustomerActions.setIsGettingCustomerLoan(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerByPhoneAction = (query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneCustomerByPhoneService(query);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerByPhone(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      if (res?.response?.status === 400) {
        notification.error({
          message:
            "We couldn't find your phone number. Please ensure you're using the number provided during registration and try again",
        });
        dispatch(myCustomerActions.setIsFetching(false));
        return false;
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
