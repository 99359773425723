import React from 'react'
import customerImage from "../../../assets/images/Group_1.png"
import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
const CustomerCard = () => {
    const {wareHouse,customer}=useSelector((state:any)=>state)
  return (
    <div className='w-full lg:max-w-[75%] h-[136px] border border-[#03022940] rounded-xl'>
        {(wareHouse?.isFetching || customer.isFetching)? <Skeleton/>:
        <div className='flex gap-3 pt-6 px-2'>
            <div className='w-[52px] h-[52px]'>
                <img src={wareHouse?.createdCart?.data?.customer?.picture? wareHouse?.createdCart?.data?.customer?.picture :customerImage} alt="" className='w-full h-full'/>
            </div>
            <div className='flex flex-col gap-1 font-medium'>
                <h1 className='text-[#030229] text-sm'>{wareHouse?.createdCart?.data?.customer?.name}</h1>
                <p className='text-[#605BFF] text-sm'>{wareHouse?.createdCart?.data?.customer?.nid}</p>
                <p><span className='text-[#030229B2] text-sm'>Email:</span> <span className='text-[#030229] text-sm'>{wareHouse?.createdCart?.data?.customer?.email}</span></p>
                <p><span className='text-[#030229B2] text-sm'>Phone:</span> <span className='text-[#030229] text-sm'>{wareHouse?.createdCart?.data?.customer?.phone}</span></p>
            </div>

        </div>}
        
        </div>
  )
}
export default CustomerCard