import { getOneCustomerByQueryAction } from "../../../store/customer/actions";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AddNewCustomer from "../../../components/forms/NewCustomer";
import { ReactComponent as AddCustomerPlaceholderImage } from "../../../assets/images/EnterCustomerInfoOption.svg";
import { myCustomerActions } from "../../../store/customer";
import CustomSwitch from "../../buttons/CustomSwitch";
import SearchBarV2 from "../../elements/SearchBarV2";
import { Divider } from "antd";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";

const AddCustomer = () => {
  const { auth, customer, wareHouse } = useSelector((state: any) => state);
  const [rejectCustomer, setRejectCustomer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(
    !customer?.isFetching && wareHouse?.createdCart?.data?.customer
  );

  const [isBusiness, setIsBusiness] = useState(false);
  const [customerQuery, setCustomerQuery] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const dispatch = useDispatch();
  const { cartId } = useParams();

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const onSearch = async (e: any) => {
    e.preventDefault();
    auth?.token &&
      (await getOneCustomerByQueryAction(
        auth?.token,
        `?phone=${customerQuery}`
      )(dispatch));
  };

  useEffect(() => {
    wareHouse?.createdCart?.data?.customer && setIsModalOpen(true);
  }, [wareHouse?.createdCart?.data?.customer]);

  useEffect(() => {
    const addCustomer = async () => {
      customer?.new?.data &&
        (await getOneCustomerByQueryAction(
          auth?.token,
          `?phone=${customer?.new?.data?.phone}`
        )(dispatch));
      dispatch(myCustomerActions.setNew(null));
      dispatch(myCustomerActions.setUpdated(null));
      dispatch(myCustomerActions.setSearchResult(null));
    };
    addCustomer();
  }, [auth?.token, customer?.new, customer?.searchResult, dispatch]);

  const handleCancel = async () => {
    setRejectCustomer(true);
    if (auth?.token) {
      const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
        customer: null,
      })(dispatch);
      setIsModalOpen(false);
      if (res) {
        setRejectCustomer(false);
      }
    }
  };

  useEffect(() => {
    if (auth?.token && customer?.updated && cartId) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  }, [auth?.token, customer?.updated, dispatch]);

  const handleSwitchChange = (e: any) => {
    setIsBusiness(e.target?.checked);
  };

  const handleOnSearchChange = (e: any) => {
    setCustomerQuery(e.target.value?.replace("+", "")?.trim());
  };

  return (
    <div>
      {/* customer info */}
      {!isModalOpen && (
        <>
          <div className="flex items-center justify-center py-2 space-x-2">
            <div className="rounded-lg">
              <form method="get" onSubmit={onSearch}>
                <SearchBarV2
                  placeholder="Input customer number"
                  onChange={handleOnSearchChange}
                  name="customer"
                  isLoading={customer.isFetching}
                />
              </form>
            </div>
            <button
              onClick={() => setIsModalOpen(true)}
              className="shadow py-2 px-4 border bg-slate-300 rounded-md"
            >
              <PlusOutlined className="m-0 p-0" />
            </button>
          </div>

          <div className="flex justify-center">
            <AddCustomerPlaceholderImage className="h-44" />
          </div>
        </>
      )}

      {isModalOpen && (
        <div className="w-full px-4 py-1">
          <div className="md:flex justify-between">
            <p className="text-lg font-semibold py-2 md:py-0">
              Contact Details
            </p>
            <CustomSwitch
              handleChange={handleSwitchChange}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
            />
          </div>
          <Divider className="m-0 p-0" />
          <div className="flex justify-between mb-2"></div>
          {
            <AddNewCustomer
              dataToUpdate={wareHouse?.createdCart?.data?.customer}
              isBusiness={activeTab !== 1}
              onCancel={handleCancel}
              rejectCustomer={rejectCustomer}
              action={
                wareHouse?.createdCart?.data?.customer ? "update" : "save"
              }
            />
          }
        </div>
      )}
    </div>
  );
};

export default AddCustomer;
