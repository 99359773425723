import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../components/Modals/CustomModal";
import {
  handleOpenRepaymentModel,
  handleSelectInvoiceToRepay,
} from "../../../store/layout/actions";
import ConfirmRepayment from "./ConfirmRepayment";
import MomoWaitingAndSuccess from "./MomoWaitingAndSuccess";

const LoanSummary = () => {
  const { customer, layout, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    handleOpenRepaymentModel(false)(dispatch);
    handleSelectInvoiceToRepay(null, 0)(dispatch);
  };

  const handlerSelectInvoice = (data: any) => {
    handleSelectInvoiceToRepay(data, data?.totalAmountDue)(dispatch);
    handleOpenRepaymentModel(true)(dispatch);
  };

  useEffect(() => {
    if (layout?.selectedInvoiceToPay === null) {
      handleOpenRepaymentModel(false)(dispatch);
    }
  }, [dispatch, layout?.selectedInvoiceToPay]);

  const isButtonNotHidden =
    !customer?.isGettingCustomerLoan &&
    !layout?.openRepaymentModel &&
    !pos?.openWaitingModal &&
    customer?.customerLoan?.totalAmountToBePaid !== 0;

  return (
    <>
      <div className="w-full h-full py-2 px-5 text-[#030229] font-normal">
        {isButtonNotHidden && (
          <div className="flex mx-auto items-center justify-center pt-6">
            <button
              className="bg-[#605BFF] w-full md:w-1/2 text-[14px] px-10 py-2 rounded-[5px] text-white"
              onClick={() => handlerSelectInvoice(customer?.customerLoan)}
            >
              PAY NOW
            </button>
          </div>
        )}
      </div>
      <CustomModal
        openModal={layout?.openRepaymentModel}
        cancelModal={handleCloseModal}
        width={500}
        component={<ConfirmRepayment />}
      />
      <CustomModal
        openModal={pos?.openWaitingModal}
        width={500}
        component={<MomoWaitingAndSuccess />}
      />
    </>
  );
};
export default LoanSummary;
