import { notification } from "antd";
import { vaultManagementActions } from ".";
import { subLayoutActions } from "../layout";
import {
  getDeviceInfoService,
  getDeviceUploadedService,
  uploadDeviceToVaultService,
} from "./services";

export const uploadDeviceToVaultAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(vaultManagementActions.setIsFetching(true));
      dispatch(subLayoutActions.setIsFetching(true));
      const res = await uploadDeviceToVaultService(data, token);
      if (res?.status === 200) {
        dispatch(vaultManagementActions.setIsFetching(false));
        dispatch(subLayoutActions.setIsFetching(false));
        if (res?.data?.uploadStatus === "FAILED") {
          dispatch(
            subLayoutActions.setCheckIsDeviceUploaded({
              type: false,
              uploadID: null,
            })
          );
          dispatch(subLayoutActions.setPaymentStatus("vault_fail"));
          return notification.error({
            message: res?.data?.deviceList[0]?.resultCode,
          });
        } else if (res?.data?.uploadStatus === "COMPLETED") {
          dispatch(subLayoutActions.setPaymentStatus("done"));
          const res = await getDeviceInfoService(data, token);
          if (res?.status === 200) {
            dispatch(vaultManagementActions.setIsFetching(false));
            dispatch(subLayoutActions.setIsFetching(false));
            dispatch(vaultManagementActions.setDeviceInfo(res));
            return { type: true, data: res?.data };
          }
        }
        return { type: true, data: res?.data };
      } else if ([500, 400]?.includes(res?.response?.status)) {
        dispatch(subLayoutActions.setPaymentStatus("vault_fail"));
        dispatch(vaultManagementActions.setIsFetching(false));
        dispatch(subLayoutActions.setIsFetching(false));
        return { type: false, data: {} };
      }
      dispatch(subLayoutActions.setPaymentStatus("vault_fail"));
      dispatch(vaultManagementActions.setIsFetching(false));
      dispatch(subLayoutActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      dispatch(vaultManagementActions.setIsFetching(false));
      console.log(err);
    }
  };
};

export const getDeviceUploadedAction = (token: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(vaultManagementActions.setIsFetching(true));
      const res = await getDeviceUploadedService(token, query);
      if (res?.status === 200) {
        if (res?.data?.uploadStatus === "FAILED") {
          dispatch(
            subLayoutActions.setCheckIsDeviceUploaded({
              type: false,
              uploadID: null,
            })
          );
          dispatch(subLayoutActions.setPaymentStatus("vault_fail"));
          return notification.error({
            message: res?.data?.deviceList[0]?.resultMessage,
          });
        } else if (res?.data?.uploadStatus === "COMPLETED") {
          dispatch(
            subLayoutActions.setCheckIsDeviceUploaded({
              type: false,
              uploadID: null,
            })
          );
          dispatch(subLayoutActions.setPaymentStatus("done"));
        }
        dispatch(vaultManagementActions.setIsFetching(false));
        return { type: true, data: res?.data };
      }
      dispatch(vaultManagementActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDeviceInfoAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(vaultManagementActions.setIsFetching(true));
      const res = await getDeviceInfoService(data, token);
      if (res?.status === 200) {
        dispatch(vaultManagementActions.setIsFetching(false));
        dispatch(vaultManagementActions.setDeviceInfo(res));
        return { type: true, data: res?.data };
      } else if (res?.status === 404) {
        dispatch(vaultManagementActions.setIsFetching(false));
        return { type: false, data: {} };
      }
      dispatch(vaultManagementActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      dispatch(vaultManagementActions.setIsFetching(false));
      console.log(err);
    }
  };
};
