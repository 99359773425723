import { Carousel } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import { getAllPlansAction } from "../../device_financing/Subscription/redux/planManagement/actions";
import { getSpecificationValue } from "../../../utils/converter";
import { PlanSkeleton } from "../../../components/skeleton/Card";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
const AllPlans = () => {
  const { auth, planManagement, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [selectePlan, setselectePlan] = useState("monthly");
  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

  useEffect(() => {
    getAllPlansAction(
      auth?.token,
      `?&channel=${channelId}`
    )(dispatch);
  }, [auth?.token, dispatch, channelId]);

  return (
    <div>
      <div className="w-full px-6 pt-4 text-[#030229]">
        <h1 className="text-lg sm:text-xl font-medium mb-2 2xl:mb-6 opacity-90">
          Available Devices
        </h1>
        {planManagement?.isFetching ? (
          <div className="grid md:grid-cols-2 2xl:grid-cols-4 gap-8">
            {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
              <PlanSkeleton />
            ))}
          </div>
        ) : (
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
            {planManagement?.all?.data
              ?.filter((el: any) => el?.duration)
              ?.map((plan: any) => {
                const capacity = getSpecificationValue(
                  plan?.pricelistItem?.specification,
                  ["capacity (rom)", "rom", "capacity"]
                );
                const memory = getSpecificationValue(
                  plan?.pricelistItem?.specification,
                  ["memory", "memory (ram)", "ram", "memory(ram)"]
                );

                const price =
                  selectePlan === "daily"
                    ? plan?.dailyInstalment
                    : selectePlan === "weekly"
                      ? plan?.weeklyInstalment
                      : plan?.monthlyInstalment;

                return (
                  <div className="border-[1px] rounded-xl p-6 text-center bg-white "
                    style={{ boxShadow: '0px 2px 14px 0px rgba(3, 2, 31, 0.06' }}>
                    <div className="flex items-center gap-x-3">
                      <div style={{ width: "6rem"}}>
                        <Carousel autoplay arrows>
                          {plan?.pricelistItem?.product?.images?.length >
                            0 ? (
                            plan?.pricelistItem?.product?.images?.map(
                              (el: any) => (
                                <img
                                  src={el}
                                  alt={`images`}
                                  className=" w-[70px] h-[80px] md:w-24 md:h-24 object-cover"
                                />
                              )
                            )
                          ) : (
                            <img
                              src={defaultPic}
                              alt={`images`}
                              className="w-[70px] h-[80px] md:w-24 md:h-24 object-cover"
                            />
                          )}
                        </Carousel>
                      </div>

                      <div className="text-start">
                        <h1 className="text-base lg:text-lg font-medium mb-2">
                          {plan?.pricelistItem?.product?.model}
                        </h1>
                        <div className="text-left font-normal flex flex-col gap-1">
                          <p className="text-sm text-gray-500 capitalize">
                            <span className="text-[#030229] text-sm">Brand : </span>{" "}
                            {plan?.pricelistItem?.product?.brand}
                          </p>
                          <p className="text-sm text-gray-500">
                            <span className="text-[#030229] text-sm">
                              Capacity(ROM) :{" "}
                            </span>{" "}
                            {capacity}
                          </p>
                          <p className="text-sm text-gray-500 mb-4">
                            <span className="text-[#030229] text-sm">
                              Memory(RAM) :{" "}
                            </span>{" "}
                            {memory}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="text-left mb-4 mt-2">
                      <h4 className="text-sm font-medium mb-2">
                        Includes:
                      </h4>
                      <ul className="space-y-1">
                        <li className="flex items-center">
                          <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                            <svg
                              className="flex-shrink-0 w-[10px] h-[10px] text-blue-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M1 5.917 5.724 10.5 15 1.5"
                              />
                            </svg>
                          </div>
                          <p
                            className={`${plan?.bundle?.at(0) === undefined &&
                              "line-through"
                              } text-[13px] text-gray-700`}
                          >
                            Bundles :{" "}
                            {`${plan?.bundle?.at(0) || "0"}  / Per Month`}
                          </p>
                        </li>
                        <li className="flex items-center">
                          <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                            <svg
                              className="flex-shrink-0 w-[10px] h-[10px] text-blue-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M1 5.917 5.724 10.5 15 1.5"
                              />
                            </svg>
                          </div>
                          <p
                            className={`${plan?.bundle?.at(0) === undefined &&
                              "line-through"
                              } text-[13px] text-gray-700`}
                          >
                            SMS : {`${plan?.sms?.at(0) || "0"} / Per Month`}
                          </p>
                        </li>
                        <li className="flex items-center">
                          <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                            <svg
                              className="flex-shrink-0 w-[10px] h-[10px] text-blue-500 "
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M1 5.917 5.724 10.5 15 1.5"
                              />
                            </svg>
                          </div>
                          <p
                            className={`${plan?.bundle?.at(0) === undefined &&
                              "line-through"
                              } text-[13px] text-gray-700`}
                          >
                            Airtime :{" "}
                            {`${plan?.call?.at(0) || "0"} / Per Month`}
                          </p>
                        </li>
                      </ul>
                    </div>

                    <div className="grid grid-cols-2 text-start  mb-4">
                      <div className="pl-2">
                        <p className="text-base  font-semibold">{`${plan?.initialPayment?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</p>
                        <span className="text-sm">Upfront</span>
                      </div>
                      <div className="border-r pr-1">
                        <p className="text-base font-semibold">{`${price?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}</p>
                        <span className="capitalize text-sm">{`/${selectePlan}`}</span>
                      </div>
                    </div>
                    <p className="text-sm text-[#03022990] mb-4 text-start">
                      <span className="font-medium pr-2 text-base">{`${plan?.duration ?? "N/A"
                        } Months`}</span>
                      Duration
                    </p>
                    <p className="text-sm mb-4 text-start">
                      <span className="font-light">Bussiness Product :</span>
                      <span className="font-medium pr-2 text-sm"> {`${plan?.businessProductType ?? "N/A"
                        }`}</span>
                    </p>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
export default AllPlans;
