import { DatePicker, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  checkCustomerLoanAction,
  getOneCustomerByPhoneAction,
} from "../../../store/customer/actions";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const CheckInfos = () => {
  const { customer } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const customerId = customer?.customerByPhone && customer?.customerByPhone._id;

  const handleGetInfos = async (country: string) => {
    setPhoneNumber(country);
    if (country.length === 12) {
      setLoading(true);
      await getOneCustomerByPhoneAction(`?phone=${country}`)(dispatch);
      setLoading(false);
    }
  };

  const maskName = (name: string) => {
    if (!name) return "";
    return name.substring(0, 2) + "*".repeat(name.length - 2);
  };
  const originalFirstName = customer?.customerByPhone?.firstName;
  const originalLastName = customer?.customerByPhone?.lastName;
  const maskedFirstName = maskName(originalFirstName);
  const maskedLastName = maskName(originalLastName);

  const handleFinish = async (values: any) => {
    const formattedDateOfBirth = values.dateOfBirth
      ? dayjs(values.dateOfBirth).format("YYYY-MM-DD")
      : null;
    const res = await checkCustomerLoanAction(customerId as string, {
      ...values,
      dateOfBirth: formattedDateOfBirth,
    })(dispatch);
    if (res) {
      navigate(
        `/payment/${customer?.customerByPhone?.phone}/account/${customer?.customerByPhone?._id}`
      );
    }
  };

  useEffect(() => {
    if (customer?.customerByPhone) {
      form.setFieldsValue({
        firstName: maskedFirstName,
        lastName: maskedLastName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerByPhone, form]);

  return (
    <div className="w-full h-full flex items-center xjustify-center flex-col md:w-1/2 bg-white py-4 px-3 md:px-24 text-[#030229] mx-auto overflow-y-auto">
      <h1 className="text-[20px] font-medium text-center py-4 mb-4">
        Loan Repayment
      </h1>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        className="w-full md:w-[500px]"
      >
        <Form.Item
          label={
            <span className="opacity-70 text-[16px]">
              <span className="text-red-500">*</span> Enter your phone number:
            </span>
          }
          validateStatus={
            phoneNumber.length > 0 && phoneNumber.length !== 12
              ? "error"
              : undefined
          }
          help={
            phoneNumber.length > 0 && phoneNumber.length !== 12
              ? "Phone Number should be exactly 12 digits."
              : undefined
          }
        >
          <PhoneInput
            value={phoneNumber}
            onChange={handleGetInfos}
            country={"rw"}
            buttonStyle={{
              height: "50px",
              border: "1px solid #03022930",
            }}
            inputStyle={{
              height: "50px",
              width: "100%",
              border: "1px solid #03022930",
              borderRadius: "10px",
            }}
            containerClass="phone-container"
            placeholder="+250 780 000 000"
          />
          {loading && (
            <div className="flex justify-center mt-10">
              <Spin size="large" />
            </div>
          )}
        </Form.Item>
        {customer?.customerByPhone && (
          <>
            <div className="mt-6">
              <Form.Item
                name="firstName"
                label={
                  <span className="opacity-70 text-[16px]">
                    Complete your First Name:
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "First name is required!",
                    whitespace: true,
                  },
                  {
                    validator: (_, value) =>
                      value === originalFirstName
                        ? Promise.resolve()
                        : Promise.reject("First name does not match!"),
                  },
                ]}
              >
                <Input
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid #03022930",
                    borderRadius: "10px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label={
                  <span className="opacity-70 text-[16px]">
                    Complete your Second Name:
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Last name is required!",
                    whitespace: true,
                  },
                  {
                    validator: (_, value) =>
                      value === originalLastName
                        ? Promise.resolve()
                        : Promise.reject("Last name does not match!"),
                  },
                ]}
              >
                <Input
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid #03022930",
                    borderRadius: "10px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="dateOfBirth"
                className="w-[100%]"
                label={
                  <span className="text-[#0F0F47] text-[16px]">
                    Enter your date of birth:
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Date of birth is required!",
                  },
                  {
                    validator: (_, value) => {
                      if (!value)
                        return Promise.reject("Please select a valid date.");
                      const isValidDate = dayjs(value).isValid();
                      return isValidDate
                        ? Promise.resolve()
                        : Promise.reject("Invalid date format!");
                    },
                  },
                ]}
              >
                <DatePicker format="YYYY-MM-DD" className="w-[100%] h-[50px]" />
              </Form.Item>
            </div>
            <div className="px-8 flex justify-center items-center">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  minWidth: "100%",
                  backgroundColor: "#605BFF",
                  "&:hover": {
                    backgroundColor: "#4E4ACF",
                  },
                  height: "40px",
                  borderRadius: "5px",
                }}
                loading={customer.isFetching}
              >
                CHECK LOAN
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
export default CheckInfos;
