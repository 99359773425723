import { Stack, Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Select } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProductActions } from "../../store/product";
import {
  getAllProductsAction,
  getAllAttributesAction,
} from "../../store/product/actions";
import { getPricelistItemAction } from "../../store/channel/actions";
import CustomButton from "../buttons/CustomButton";
import { MdFilterList } from "react-icons/md";
const { Option } = Select;

const NoSaleFilter = (props: any) => {
  const { product, auth, channel, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState(false);
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(40);

  useEffect(() => {
    dispatch(
      myProductActions.setQuery(
        `?${type && `type=${type}&${brand && `brand=${brand}`}`}`
      )
    );
  }, [brand, dispatch, type]);

  const resetFilter = () => {
    if (selectedFilters) {
      setType("");
      setBrand("");
      props?.setProductId("");
    }
  };

  useEffect(() => {
    const fetchingData = async () => {
      auth?.token &&
        getAllProductsAction(
          auth?.token,
          product?.query + `&limit=${limit}&page=${page - 1}` || "?"
        )(dispatch);
      auth?.token &&
        (await getAllAttributesAction(
          auth?.token,
          product?.query || "?"
        )(dispatch));
    };
    fetchingData();
  }, [auth, product?.query, dispatch, limit, page]);

  useEffect(() => {
    props?.productId &&
      auth?.token &&
      getPricelistItemAction(
        auth?.token,
        `?channel=${
          auth?.user?.shop?.channel ?? auth?.user?.shop?.channelId
        }&product=${props?.productId}`
      )(dispatch);
  }, [
    auth?.token,
    auth?.user?.shop?.channel,
    auth?.user?.shop?.channelId,
    dispatch,
    props?.productId,
  ]);

  useEffect(() => {
    if (props?.productId === "") {
      getPricelistItemAction(
        auth?.token,
        `?channel=${
          auth?.user?.shop?.channel ?? auth?.user?.shop?.channelId
        }&limit=40`
      )(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.productId]);

  return (
    <div className="sm:px-4 py-2">
      <div className=""
      >
        <div className="flex gap-5 sm:gap-5 items-center"
        >
          <CustomButton title={"Filter"} icon={selectedFilters ?"":<MdFilterList size={20}/>} textColor="#030229B2" textSize="base" onClick={() => {
              setSelectedFilters(!selectedFilters);
              resetFilter();
            }}/>

          {selectedFilters && (
            <div className="flex flex-col sm:flex-row gap-5 items-center"
            >
              <Select
                showSearch
                style={{ width: 150 }}
                className="border border-[#0302291A] rounded-md"
                allowClear
                placeholder="Filter By Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                loading={product.isFetching}
                disabled={product.isFetching}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
                options={product?.attributes?.types}
                onChange={(value: any) => {
                  !value && setType("");
                  !value && setBrand("");
                  !value && props?.setProductId(" ");
                  value && setType(value);
                }}
              />
              {type && (
                <Select
                  loading={product.isFetching}
                  disabled={product.isFetching}
                  className="border border-[#0302291A] rounded-md mt-3 sm:mt-0"
                  showSearch
                  allowClear
                  style={{ width: 150 }}
                  placeholder="Filter By Brand"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  options={product?.attributes?.brands}
                  onChange={(value: any) => {
                    !value && setBrand("");
                    !value && props?.setProductId(" ");
                    value && setBrand(value);
                  }}
                />
              )}
              {brand && (
                <Select
                  loading={product.isFetching || channel.isFetching}
                  disabled={product.isFetching || channel.isFetching}
                  className="border border-[#0302291A] rounded-md mt-3 sm:mt-0"
                  showSearch
                  placeholder="Filter By Product"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")

                      .toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? "").toString().toLowerCase()
                      )
                  }
                  onChange={(value: any) => {
                    !value && props?.setProductId(" ");
                    value && props?.setProductId(value);
                  }}
                  // onSearch={onSearch}
                >
                  {product?.all?.data?.map((el: any) => (
                    <Option value={el?._id}>{el?.model}</Option>
                  ))}
                </Select>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoSaleFilter;
