import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "inventory",
  initialState: {
    isFetching: false,
    isUpdated:false,
    all: { data: [] },
    selected: null,
    selectedDeliveryNote: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    filters: null,
    allShopStock: null,
    stockItems: null,
    searchBySn: null,
    shopStats:null,
    recentActivities:null,
    transfer:null,
    newTransfer:null,
    allTeamLead:null,
    searchByEmies:null,
    transferDetails:null,
    statusCount:null,
    updateTransfer:null,
    updateTeamTransfer:null,
    isUpdatedOne:null,
    isReturn:null,
    freelancerStock:null,
    stockStatusCount:null,
    transferTowarehouse:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllShopStock(state, action) {
      state.allShopStock = action.payload;
    },
    setStockItems(state, action) {
      state.stockItems = action.payload;
    },
    setSearchBySn(state, action) {
      state.searchBySn = action.payload;
    },
    setShopStats(state, action) {
      state.shopStats = action.payload;
    },
    setRecentActivities(state, action) {
      state.recentActivities = action.payload;
    },
    setTransfer(state, action) {
      state.transfer = action.payload;
    },
    setNewTransfer(state, action) {
      state.newTransfer = action.payload;
    },
    setAllTeamLead(state, action) {
      state.allTeamLead = action.payload;
    },
    setSearchByEmies(state, action) {
      state.searchByEmies = action.payload;
    },
    setTransferDetails(state,action){
      state.transferDetails =action.payload
    },
    setStatusCount(state,action){
      state.statusCount =action.payload
    },
    setUpdateTransfer(state,action){
      state.updateTransfer =action.payload
    },
    setUpdateTeamTransfer(state,action){
      state.updateTeamTransfer =action.payload
    },
    setIsUpdated(state,action){
      state.isUpdated =action.payload
    },
    setIsUpdatedOne(state,action){
      state.isUpdatedOne =action.payload
    },
    setIsReturn(state,action){
      state.isReturn =action.payload
    },
    setFreelancerStock(state,action){
      state.freelancerStock =action.payload
    },
    setStockStatusCount(state,action){
      state.stockStatusCount =action.payload
    },
    setTransferTowarehouse(state,action){
      state.transferTowarehouse =action.payload
    },
    
  },
});

export const myInventoryActions = mySlice.actions;

export default mySlice.reducer;
