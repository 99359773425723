import React, { useEffect} from "react";
import { Skeleton, Tabs } from "antd";
import type { TabsProps } from "antd";
import PersonalDetailsData from "../components/PersonalDetailsData";
import ApplicantsDocument from "../components/ApplicantsDocument";
import ApplicantsPlans from "../components/ApplicantsPlans";
import { useDispatch, useSelector } from "react-redux";
import CofirmPayment from "../../device_financing/Subscription/components/models/CofirmPayment";
import CustomModal from "../../../components/Modals/CustomModal";
import {
  handlConfirmPaymentModelAction,
  handleOpenPaymentModelAction,
} from "../../device_financing/Subscription/redux/layout/actions";
import LoadingPaymentProcess from "../components/LoadingPaymentProcess";
import { getApplicantsDetailsAction } from "../redux/businessPlans/action";
import { useNavigate} from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const ApplicantDetails = () => {
  const { auth, apllicants, subLayout } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const onChange = (key: string) => {
    console.log(key);
  };
  const data = apllicants?.applicantDetails?.data;
  const phone = data?.customer?.phone;
  const maskedPhone = phone
    ? `${phone.slice(0, 5)}*********${phone.slice(-2)}`
    : "";
  const NId = data?.customer?.nationalId;
  const maskedNID = NId ? `${NId.slice(0, 2)}*********${NId.slice(-2)}` : "";
  const handleConfirmPaymant = () => {
    handleOpenPaymentModelAction(true)(dispatch);
  };
  const handleClosePaymentModel = async (model: string) => {
    if (model === "payment") {
      await handleOpenPaymentModelAction(false)(dispatch);
    } else {
      await handlConfirmPaymentModelAction(false)(dispatch);
    }
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: <h1 className="text-sm font-medium">Personal Details</h1>,
      children: <PersonalDetailsData data={data} />,
    },
    {
      key: "2",
      label: <h1 className="text-sm font-medium">Documents</h1>,
      children: (
        <ApplicantsDocument
          data={apllicants?.applicantDetails?.data?.customer?.subscriptionInfo}
        />
      ),
    },
    {
      key: "3",
      label: <h1 className="text-sm font-medium">Plan Details</h1>,
      children: <ApplicantsPlans data={apllicants?.applicantDetails} />,
    },
  ];
  const applicantId = localStorage.getItem("applicantId");
  useEffect(() => {
    if (applicantId && auth?.token)
      getApplicantsDetailsAction(auth?.token, applicantId as string)(dispatch);
  }, [dispatch, applicantId, auth?.token]);
  return (
    <div className="w-full mt-16">
      <DetailsHeaderActionBar
        title="Applicants"
        goBack={goBack}
        item="Applicants"
      />
      <CustomModal
        openModal={subLayout?.openPaymentModel}
        cancelModal={() => handleClosePaymentModel("payment")}
        width={1000}
        component={
          <CofirmPayment onCancel={() => handleClosePaymentModel("payment")} />
        }
      />

      <CustomModal
        openModal={subLayout?.confirmPaymentModel}
        width={800}
        // closable={false}
        component={
          <LoadingPaymentProcess
            onCancel={() => handleClosePaymentModel("cofirmProcess")}
            from="applications"
          />
        }
      />
      <div className="  w-full my-6 bg-white shadow-sm border border-slate-200 rounded-lg py-3">
        <div className="w-full relative flex justify-center items-center content-center">
          <div className="absolute  rounded-full w-28 h-28 ">
            <img
              src={data?.customer?.picture}
              alt=""
              className="w-full h-full rounded-full"
            />
          </div>
        </div>

        <div className="p-4 mt-16 text-center">
          <p className="mb-2 text-slate-800 text-xl font-semibold">
            {data?.customer?.name}
          </p>
          <p className="text-[#605BFF] text-sm">{maskedNID}</p>
          <p className="text-slate-600 leading-normal font-light">
            {data?.customer?.email} {maskedPhone}
          </p>
        </div>
        {apllicants?.isFetching ? (
          <Skeleton />
        ) : (
          <div className="w-full flex justify-center items-center content-center gap-4">
            <p className="bg-[#D9D9D980] rounded-full px-5 text-center py-2 text-sm capitalize">
              {data?.status}
            </p>
          </div>
        )}
      </div>
      <div className="my-5">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  );
};

export default ApplicantDetails;
