import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  containsKey,
  getActiveShop,
  getSpecificationValue,
} from "../../../../utils/converter";
import { LoadingButton } from "@mui/lab";
import { handleNextStepAction } from "../redux/layout/actions";
import { createAccountAction } from "../../../../store/pos/actions";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, notification, Skeleton } from "antd";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";
import { customerValidationAction } from "../redux/customer/actions";
import { TiUser } from "react-icons/ti";

const CustomerDetails = () => {
  const { wareHouse, auth, pos, company, subCustomer, appSettings, shop } =
    useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const customer = wareHouse?.createdCart?.data?.customer;
  const { cartId } = useParams();
  const navigate = useNavigate();

  const isSubmitted = wareHouse?.createdCart?.data?.isSubscriptionSubmitted;

  const shopName =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?.name || shop?.myShop?.name;

  const planDetails = wareHouse?.createdCart?.data?.selectedPlan;
  const capacity = getSpecificationValue(
    planDetails?.pricelistItem?.specification,
    ["capacity (rom)", "rom", "capacity"]
  );

  const memory = getSpecificationValue(
    planDetails?.pricelistItem?.specification,
    ["memory", "memory (ram)", "ram", "memory(ram)"]
  );

  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;

  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;

  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handlePrevious = async () => {
    if (auth?.user?.profile === "freelancer") {
      navigate(`/apply/${wareHouse?.createdCart?.data?._id}`);
    } else {
      handleNextStepAction(3)(dispatch);
    }
  };

  const handleSendRequest = async () => {
    if (wareHouse?.createdCart?.data?.isSubscriptionSubmitted) {
      auth?.user?.profile === "freelancer"
        ? navigate("/cart")
        : navigate("/sale");
    } else {
      const resPre = await updateShopStockCartDetailsAction(
        auth?.token,
        cartId,
        {
          isSubscriptionSubmitted: true,
          subscriptionStatus: "ACCOUNT_REQUEST",
          status: "requested",
          payment: [
            {
              mode: "MoMo_Pay",
              amount:
                planDetails?.planType?.toLowerCase() === "prepaid"
                  ? planDetails?.initialPayment + planDetails?.monthlyInstalment
                  : planDetails?.initialPayment,
            },
          ],
          isNotify: true,
          notification: {
            action: "Device Installment Request",
            role: [
              "sales-manager",
              "sales-supervisor",
              "dev",
              "admin",
              "finance-manager",
            ],
            message: `${auth?.user?.names} from ${shopName} has send a device installment request`,
            title: "Device Installment Request",
          },
        }
      )(dispatch);
      if (resPre) {
        if (wareHouse?.createdCart?.data?.status !== "rejected") {
          const isAccountCreated = await createAccountAction(
            auth?.token,
            cartId as string
          )(dispatch);
          if (isAccountCreated) {
            auth?.user?.profile === "freelancer"
              ? navigate("/cart")
              : navigate("/sale");
          }
        } else {
          auth?.user?.profile === "freelancer"
            ? navigate("/cart")
            : navigate("/sale");
        }
        notification.success({ message: "Request Sent Successfully" });
      }
    }
  };

  const amount =
    wareHouse?.createdCart?.data?.instalmentPlan === "daily"
      ? planDetails?.dailyInstalment
      : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
        ? planDetails?.weeklyInstalment
        : planDetails?.monthlyInstalment;

  useEffect(() => {
    customerValidationAction(
      wareHouse?.createdCart?.data?.customer?._id,
      {},
      auth?.token
    )(dispatch);
  }, [dispatch]);
  const customerFailedValue =
    subCustomer?.userValidation?.data?.failedCustomerData;

  return (
    <div className="max-w-4xl mx-auto p-4 text-[#030229]">
      {isOpen ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Personal Details */}
          <div>
            <h3 className="text-[16px] font-medium mb-4">PERSONAL DETAILS</h3>
            {customerFailedValue?.length > 0 && (
              <Alert
                message="The phone number given does not match some customer data. (Verify the ones with line through.)"
                type="error"
              />
            )}
            {subCustomer?.invalidUserData?.data?.status === 400 && (
              <Alert
                message="The phone number is invalid. If you do not include a valid phone number, your request will be rejected."
                type="error"
              />
            )}
            {subCustomer?.isFetching ? (
              <Skeleton />
            ) : (
              <ul className="space-y-2 mt-2">
                <li className="flex gap-1">
                  <span
                    className={`font-normal text-sm flex gap-2 ${(containsKey(customerFailedValue, "firstName") ||
                      containsKey(customerFailedValue, "lastName")) &&
                      "line-through"
                      }`}
                  >
                    <TiUser size={19} />   {customer?.name}
                  </span>
                  {(containsKey(customerFailedValue, "firstName") ||
                    containsKey(customerFailedValue, "lastName")) && (
                      <IoMdClose color="red" className="mt-2" />
                    )}
                </li>
                <li className="flex gap-2 ">
                  <span className="text-sm">📞 {`+${customer?.phone}`}</span>
                </li>
                <li className="flex gap-2">
                  <span
                    className={`text-sm ${containsKey(customerFailedValue, "email") &&
                      "line-through"
                      }`}
                  >
                    📧 {customer?.email || "N/A"}
                  </span>
                  {containsKey(customerFailedValue, "email") && (
                    <IoMdClose color="red" className="mt-2" />
                  )}
                </li>
                <li className="flex gap-2">
                  <span
                    className={` text-sm ${containsKey(customerFailedValue, "id") && "line-through"
                      }`}
                  >
                    ID: {customer?.nationalId}
                  </span>
                  {containsKey(customerFailedValue, "id") && (
                    <IoMdClose color="red" className="mt-1" />
                  )}
                </li>
                <li className="flex gap-1">
                  <span
                    className={`font-normal capitalize text-sm ${(containsKey(customerFailedValue, "gender") ||
                      containsKey(customerFailedValue, "gender")) &&
                      "line-through"
                      }`}
                  >
                    Gender :  {customer?.gender}
                  </span>
                  {(containsKey(customerFailedValue, "gender") ||
                    containsKey(customerFailedValue, "gender")) && (
                      <IoMdClose color="red" className="mt-2" />
                    )}
                </li>
              </ul>
            )}
            {wareHouse?.createdCart?.data?.status === "rejected" && (
              <p className="pt-2">
                Reject Reason{" "}
                <span className="text-red-400 text-sm">
                  {wareHouse?.createdCart?.data?.rejectSubReason}
                </span>{" "}
              </p>
            )}
            {/* Address Details */}
            <h3 className="text-[16px] font-medium mt-6 mb-3">ADDRESS DETAILS</h3>
            <ul className="space-y-2">
              <li>
                <span className="font-normal text-sm opacity-70">
                  Country:{" "} </span>
                <span className="font-normal text-sm ">{address?.country}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Province:{" "} </span>
                <span className="font-normal text-sm">{address?.province}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  District:{" "}</span>
                <span className="font-normal text-sm">{address?.district}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Sector: </span><span className="font-normal text-sm">{address?.sector}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Cell:</span> <span className="font-normal text-sm">{address?.cell}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Village:{" "} </span>
                <span className="font-normal text-sm">{address?.village}</span>

              </li>
            </ul>
          </div>

          {/* Plan Details */}
          <div>
            <h3 className="text-[16px] font-medium mb-4">PLAN DETAILS</h3>
            <ul className="space-y-2">
              <li>
                <span className="font-normal text-sm opacity-70">
                  Device :{" "}</span>
                <span className="font-normal text-sm capitalize">
                  {planDetails?.pricelistItem?.product?.model}
                </span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Capacity (ROM) :{" "}</span>
                <span className="font-normal text-sm">{capacity}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Memory (RAM) :  </span><span className="font-normal text-sm">{memory}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70 capitalize">
                  {`${wareHouse?.createdCart?.data?.instalmentPlan} Payment : `} </span>
                <span className="font-normal text-sm">
                  {" "}
                  {`${amount?.toLocaleString()} (${getDefaultCurrencyCode(
                    company
                  )})`}
                </span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Plan Period :{" "}</span>
                <span className="font-normal text-sm">{`${planDetails?.duration} Months`}</span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Payment Plan :{" "}</span>
                <span className="font-normal capitalize text-sm">
                  {wareHouse?.createdCart?.data?.instalmentPlan}
                </span>
              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Upfront :{" "}</span>
                <span className="font-normal text-sm">
                  {`${planDetails?.initialPayment?.toLocaleString()} (${getDefaultCurrencyCode(
                    company
                  )})`}
                </span>

              </li>
              <li>
                <span className="font-normal text-sm opacity-70">
                  Plan Type :{" "}</span>
                <span className="font-normal capitalize text-sm">
                  {`${planDetails?.planType}`}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-[16px] font-medium mb-4">Documents</h3>
          <div className="flex flex-col sm:flex-row gap-6">
            <div>
              <label htmlFor="" className="font-normal text-base">
                {`${customer?.name?.split(" ")[0]}'s Profile`}
              </label>
              <img
                src={userImage}
                alt=""
                className="h-48 object-contain w-full mt-2"
              />
            </div>
            <div>
              <label htmlFor="" className="font-normal pb-2 text-base">
                National ID
              </label>
              <img
                src={nationalId}
                alt=""
                className="h-48 object-contain w-full mt-2"
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={`flex ${isSubmitted && wareHouse?.createdCart?.data?.status !== "requested"
          ? "justify-between sm:justify-center sm:space-x-6"
          : "justify-between"
          } mt-8`}
      >
        {["pending", "requested", "rejected"]?.includes(
          wareHouse?.createdCart?.data?.status
        ) ? (
          <LoadingButton
            variant="outlined"
            onClick={handlePrevious}
            className="w-[100px] sm:w-[150px] text-lg uppercase"
            disabled={pos?.isFetching || wareHouse?.isUpdateFetching}
          >
            {auth?.user?.profile === "freelancer" ? "Edit" : "Previous"}
          </LoadingButton>
        ) : (
          wareHouse?.createdCart?.data?.status === "requested" && <div></div>
        )}
        <button
          className="text-white bg-blue-500 w-12 flex justify-center items-center h-12 pd-2 rounded-full transition-all duration-300"
          onClick={handleToggle}
        >
          <IoIosArrowDown
            size={"25px"}
            className={`transition-transform duration-300 ${!isOpen ? "rotate-180" : "rotate-0"
              }`}
          />
        </button>
        {["pending", "requested", "rejected"]?.includes(
          wareHouse?.createdCart?.data?.status
        ) ? (
          <LoadingButton
            variant="contained"
            loading={pos?.isFetching || wareHouse?.isUpdateFetching}
            onClick={handleSendRequest}
            className="w-[100px]d sm:min-w-[150px]d w-fit text-sm sm:text-lg uppercase"
          >
            {wareHouse?.createdCart?.data?.status === "requested"
              ? "Waiting for approval"
              : wareHouse?.createdCart?.data?.status === "rejected"
                ? "Re-Send request"
                : " Send request"}
          </LoadingButton>
        ) : (
          !isSubmitted && <div></div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
