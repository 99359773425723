import React, { useEffect } from "react";
import { Stack, useMediaQuery } from "@mui/material";
import MetricsCard from "../../../components/cards/purchase/MetricsCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import RepairCard from "../../../components/cards/pos/RepairCard";
import {
  getAllRepairsAction,
  getAllRepairsOverviewAction,
  getRecentActivitiesAction,
} from "../../../store/repair/actions";
import { myRepairActions } from "../../../store/repair";
import CustomButton from "../../../components/buttons/CustomButton";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import RepairTable from "../../../components/tables/RepairTable";
import { getActiveShop } from "../../../utils/converter";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const RepairOverview = (props: any) => {
  const isMobile = useMediaQuery("(max-width: 850px)");
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {};
  const { auth, customer, repair, appSettings, shop,layout } = useSelector(
    (state: any) => state
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  // const shopId =
  //   getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
  //     ?.shop?._id ?? shop?.selected?._id;
  useEffect(() => {
    auth?.token &&
      getAllRepairsAction(auth?.token, `?shop=${shopId}`)(dispatch);
  }, [auth?.token, auth?.user?._id, dispatch, shopId, limit, page]);
  useEffect(() => {
    auth?.token &&
      getAllRepairsOverviewAction(auth?.token, `?shop=${shopId}`)(dispatch);
  }, [auth?.token, shopId, dispatch]);
  useEffect(() => {
    auth?.token &&
      getRecentActivitiesAction(auth?.token, `?shop=${shopId}`)(dispatch);
  }, [auth?.token, shopId, dispatch]);

  useEffect(() => {
    dispatch(myRepairActions.setRepairSearchResult(null));
    dispatch(myRepairActions.setRepairSearchError(null));
  }, [dispatch]);

  return (
    <div className="text-black py-6 scrollbar-hide overflow-y-auto h-[89vh]">
     <div className="flex justify-between">
      <h1 className="text-[#030229d8] text-base lg:text-xl pb-2">Repair</h1>
        <CustomButton
          title={"Repair"}
          textColor="white"
          bgColor="[#605BFF]"
          onClick={() => navigate("/add/repair")}
        />
      </div>
      <div
        className={`gap-4 grid sm:grid-cols-2 ${layout?.isSideNavOpen? "md:grid-cols-2":"md:grid-cols-3"} xl:grid-cols-4 text-white`}
      >
        <OverViewCard
          text="All Repaired Devices"
          total={
            repair?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              repair?.repairOverView?.data?.allRepairedDevices
            )
          }
          icon={<BoxIcons />}
        />
        <OverViewCard
          text="Being repaired"
          total={
            repair?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              repair?.repairOverView?.data?.fixedDevices
            )
          }
          icon={<BoxIcons />}
        />
        <OverViewCard
          text="On Pending"
          total={
            repair?.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              repair?.repairOverView?.data?.pendingDevice
            )
          }
          icon={<BoxIcons />}
        />
      </div>
      <div className="w-full bg-transparent p-3 rounded-lg space-y-4 mt-5">
        <div className="bg-white w-full p-5 rounded-md h-full overflow-y-auto pr-3">
          <h1>Recent Activities</h1>
          <RepairTable
            data={repair?.recentActivities?.data}
            limit={limit}
            page={page}
            setPage={setPage}
            setLimit={setLimit}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default RepairOverview;
