import React, { useState, useRef } from "react";
import { Divider, Form, Input, Select, Space, InputRef } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { relationship } from "../../../../assets/data/relationship";
import { IoWarningOutline } from "react-icons/io5";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const WitnessForm = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);
  const [selectedCountry, setSelectedCountry] = useState("250");

  let indexBrand = 0;

  const [items, setItems] = useState([...relationship]);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...items, name || `New item ${indexBrand++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  return (
    <div>
      <div>
        <h1 className="text-[#030229] text-lg mb-4 font-medium"> Other Contacts</h1>
        <Space style={{ background: '#fed607', padding: '1px 10px', borderRadius: '5px', marginBottom:'10px'}}><p className="font-normal text-[14px]">One of Contact Person must be MUDUGUDU</p></Space>
      </div>
      <Form.List
        name="witnessInfo"
        {...formItemLayout}
        initialValue={
          wareHouse?.createdCart?.data?.customer?.subscriptionInfo &&
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo
              ?.length !== 0
            ? [
              ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                ?.witnessInfo,
            ]
            : [{}, {}]
        }
      >
        {(fields, { add, remove }) => {
          const addButtonDisabled = fields.length >= 4;
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div>
                  <div className="grid sm:grid-cols-2 2xl:grid-cols-3 gap-x-4 lg:max-2xl:gap-1 mt-2 lg:max-2xl:mt-0 ">
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      label={
                        <span className="text-[#0F0F47] text-sm">
                          First Name
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Names are required!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="FirstName"
                        className="pl-4 border"
                        style={{ height: 40 }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "lastName"]}
                      label={
                        <span className="text-[#0F0F47] text-sm">
                          Last Name
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Names are required!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Last Name"
                        className="pl-4 border"
                        style={{ height: 40 }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "phone"]}
                      label={
                        <span className="text-[#0F0F47] text-sm">Phone</span>
                      }
                      initialValue={props?.dataToUpdate?.phone}
                      rules={[
                        {
                          validator: (_, value) => {
                            const phoneNumberLength = value
                              ? value.replace(/\D/g, "").length
                              : 0;
                            if (selectedCountry?.slice(0, 3) === "250") {
                              if (phoneNumberLength === 12) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Phone Number should be exactly 9 Digits."
                                )
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <PhoneInput
                        onChange={handleCountryChange}
                        country={"rw"}
                        buttonStyle={{
                          height: "40px",
                          border: "1px solid #E5E7EB",
                        }}
                        inputStyle={{
                          height: "40px",
                          width: "100%",
                          border: "1px solid #E5E7EB",
                        }}
                        containerClass="phone-container"
                        placeholder="+250 780 000 000"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "relationship"]}
                      label={
                        <span className="text-[#0F0F47] text-sm">
                          RelationShip
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Relationsip is required!",
                        },
                      ]}
                      initialValue={name === 0 ? "mudugudu" : null}
                    >
                      <Select
                        disabled={name === 0 ? true : false}
                        style={{ display: "flex", alignItems: "center" }}
                        className="h-[40px]"
                        showSearch
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: "8px 0" }} />
                            <Space style={{ padding: "0 8px 4px" }}>
                              <Input
                                placeholder="Add New"
                                ref={inputRef}
                                onChange={onNameChange}
                              />
                              <Button variant="contained" onClick={addItem}>
                                <PlusOutlined />
                              </Button>
                            </Space>
                          </>
                        )}
                        options={items.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                      />
                    </Form.Item>

                    {key >= 2 && (
                      <div className="flex flex-row items-start mt-8 xgap-2">
                        <Button
                          onClick={() => remove(name)}
                          className=" shadow !m-0 justify-between !items-center text-red-500"
                        >
                          <MinusCircleOutlined className=" my-auto text-red-500" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div>
                <Form.Item>
                  <div className="flex items-center gap-x-3">
                    <Button
                      onClick={() => !addButtonDisabled && add()}
                      className="shadow !m-0 justify-between !items-center text-[#2943D6]"
                    // disabled={
                    //   wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                    // }
                    >
                      <PlusOutlined />
                    </Button>
                    <span className="text-blue-600"> Add More Contact</span>
                  </div>
                </Form.Item>
              </div>
            </>
          );
        }}
      </Form.List>
      {/* </Form> */}
    </div>
  );
};
export default WitnessForm;
