import { ReactComponent as HomeIcons } from "../../../assets/icons/home.svg";
import { ReactComponent as CartIcons } from "../../../assets/icons/shopping-bag.svg";
import { ReactComponent as PriceListIcons } from "../../../assets/dashboard/note-favorite.svg";
import { ReactComponent as customerIcons } from "../../../assets/dashboard/heroicons_users.svg";
import { BiTransfer } from "react-icons/bi";
export const pageList = [
  {
    id: 1,
    name: "Home",
    icon: HomeIcons,
    path: "/home",
    roles: [
      "sales-agent",
    ],
  },
  {
    id: 2,
    name: "Plans",
    icon: PriceListIcons,
    path: "/plans",
    roles: [
      "sales-agent",
    ],
  },
  // {
  //   id: 3,
  //   name: "Apply",
  //   icon: EarningsIcons,
  //   path: "/apply",
  //   roles: [
  //     "sales-agent",
  //   ],
  // },
  {
    id: 3,
    name: "Applicants",
    icon: customerIcons,
    path: "/applicants",
    roles: [
      "sales-agent",
    ],
  },
  {
    id: 4,
    name: "Carts",
    icon: CartIcons,
    path: "/cart",
    roles: [
      "sales-agent",
    ],
  },
  {
    id: 5,
    name: "Transfer",
    icon: BiTransfer,
    path: "/transfer",
    roles: [
      "sales-agent",
    ],
  },
];
