import { subLayoutActions } from ".";

export const handleNextStepAction = (step: number) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setNextStep(step));
  };
};

export const handleOpenPaymentModelAction = (isOpened: boolean) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setOpenPaymentModel(isOpened));
  };
};

export const handlConfirmPaymentModelAction = (isOpened: boolean) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setConfirmPaymentModel(isOpened));
    dispatch(subLayoutActions.setOpenPaymentModel(false));
  };
};

export const handlePaymentStatusAction = (status: string) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setPaymentStatus(status));
    if (status === "invoice") {
      dispatch(subLayoutActions.setIsCustomerPaid(true));
    }
  };
};

export const handleConfirmFinalStageAction = (confirm: boolean) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setEnableTour(false));
    dispatch(subLayoutActions.setIsFinalStage(confirm));
  };
};

export const handleOpenTourAction = (open: boolean) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setEnableTour(open));
    dispatch(subLayoutActions.setConfirmPaymentModel(false));
  };
};
export const handlecheckStateInfoAction = (status: string) => {
  return async (dispatch: any) => {
    dispatch(subLayoutActions.setStateInfoStatus(status));
  };
};
