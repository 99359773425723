import React, { useEffect, useState } from "react";
import { Button, Form, message, notification, Table, Modal } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { ReactComponent as SearchItemImage } from "../assets/images/Scan to sell Option 2.svg";
import { useDispatch, useSelector } from "react-redux";
import { 
  getSearchByEmiesActions,
  returnTransferstowarehouseActions,
  getTeamLeadTransfersActions
} from "../store/inventory/actions";
import { getActiveShop } from "../utils/converter";
import BarcodeScanner from "./BarCodeScanner";
import SearchBar from "./SearchBar";
import type { TableColumnsType } from "antd";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const TransferToWarehouse = (props: any) => {
  const { auth, appSettings, inventory } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [form] = Form.useForm();
  const [emiData, setEmiData] = useState<any>([]);

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const clearSearchData = () => {
    setScannedCode(undefined);
    setNewSearch(false);
    setIsSearching(false);
    setEmiData([]);
    form.resetFields();
  };

 
  const getCode = (code: any) => {
    setScannedCode(code);
    handleSearch(code);
  };

  const handleSearch = async (values: any) => {
    setIsSearching(true);
    const res: any = await getSearchByEmiesActions(
      auth?.token,
      `?serialNumber=${values?.query}&shopId=${shopId}`
    )(dispatch);

    if (res.data.product === "sold") {
      notification.error({
        message: "The product is sold.",
      });
    }

    if (res?.type && res?.data?.product) {
      const isDuplicate = emiData.some(
        (item: any) =>
          item?.wareHouseItem?._id === res.data.product?.wareHouseItem?._id
      );

      if (!isDuplicate) {
        setEmiData((prevData: any) => [...prevData, res.data.product]);
        form.resetFields();
      } else {
        message.warning("This device is already in the list");
      }
    }
    setIsSearching(false);
  };

  const handleRemoveDevice = (deviceId: any) => {
    setEmiData((prevData: any) =>
      prevData.filter((item: any) => item?.wareHouseItem?._id !== deviceId)
    );
    message.success("Device removed successfully");
  };

  const handleChange = () => {
    setNewSearch(true);
  };

  const handleDiscard = () => {
    clearSearchData();
    message.success("All data has been discarded");
    if (props?.setIsModalOpen) {
      props.setIsModalOpen(false);
    }
  };

  const handleDiscardWithConfirmation = () => {
    if (emiData.length > 0) {
      Modal.confirm({
        title: "Confirm Discard",
        content:
          "Are you sure you want to discard all items? This action cannot be undone.",
        okText: "Yes, Discard All",
        cancelText: "No, Keep Items",
        okButtonProps: {
          danger: true,
        },
        onOk: handleDiscard,
      });
    } else {
      handleDiscard();
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Model",
      dataIndex: "model",
      render: (text: any) => (
        <div className="px-4">
          <p className="text-xs sm:text-base">{text}</p>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text: any) => (
        <>
          {text?.map((spec: any, index: number) => (
            <div
              key={index}
              className="flex flex-row gap-3 sm:gap-3 text-xs sm:text-base"
            >
              <span className="text-xs sm:text-base">{spec[0]}:</span>{" "}
              <span className="text-xs sm:text-base">{spec[1]}</span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      render: (text: any) => (
        <div className="px-4">
          <p className="text-xs sm:text-xs">{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "data",
      render: (text: any) => (
        <div className="px-4">
          <Button
            type="link"
            danger
            onClick={() => handleRemoveDevice(text?._id)}
          >
            <DeleteFilled />
          </Button>
        </div>
      ),
    },
  ];

  const data = emiData.map((item: any) => ({
    key: item?.wareHouseItem?._id,
    model: item?.requestedItem?.product?.product?.model,
    description: item?.requestedItem?.product?.specification,
    imei: item?.wareHouseItem?.serialNumber,
    data: item?.wareHouseItem,
  }));

  return (
    <div className="flex flex-col items-center md:px-3 py-4">
      <div className="flex items-center gap-3 h-[50px]">
        <Form form={form} onFinish={handleSearch} className="flex-grow">
          <Form.Item name="query" initialValue={scannedCode} className="w-full">
            <SearchBar
              isLoading={inventory?.isFetching}
              scannedCode={newSearch ? "" : scannedCode}
              onChange={handleChange}
            />
          </Form.Item>
        </Form>
        <div className="flex items-center j-pointer whitespace-nowrap h-full px-4 mt-2">
          <BarcodeScanner getCode={getCode} />
        </div>
      </div>

      {emiData.length === 0 ? (
        <SearchItemImage />
      ) : (
        <>
          <div className="w-[100%] mb-6 pxh-4 pt-4">
            <div className="rounded-lg w-full border border-gray-200 overflow-x-auto">
              <Table<DataType>
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
              />
            </div>
          </div>

          <div className="w-full sm:w-[500px] px-4">
            <div className="flex flex-col sm:flex-row justify-between gap-3 pt-8">
              <Button
                className="px-6 py-2 border border-gray-300 rounded-md w-full sm:w-[300px] text-[#605BFF] flex items-center justify-center"
                onClick={handleDiscardWithConfirmation}
                disabled={emiData.length === 0}
              >
                Discard All
              </Button>

              <Button
                className="px-6 py-2 bg-[#605BFF] text-white rounded-md w-full sm:w-[300px] flex items-center justify-center"
                // onClick={handleWarehouseTransfer}
                disabled={isProcessing || inventory.isFetching || emiData.length === 0}
              >
                Confirm Transfer to Warehouse
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TransferToWarehouse;
