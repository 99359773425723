import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/Modals/CustomModal";
import PamentModeDrower from "../../../components/Modals/PamentModeDrower";
import AddToCart from "../../../components/AddToCart";
import { Form, Result } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ProductCard from "./productCard";
import {
  getAllShopStockAction,
  getShopStockCartAction,
  updateShopStockCartAction,
  updateShopStockCartDetailsAction,
  updateWareHouseDetailsAction,
} from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myCustomerActions } from "../../../store/customer";
import SearchBar from "../../../components/SearchBar";
import {
  getActivePlan,
  getActivePrice,
  getActiveShop,
} from "../../../utils/converter";
import { getOneCustomerByQueryAction } from "../../../store/customer/actions";
import AddCustomer from "../../../components/cards/pos/AddCustomer";
// import AddCustomerWithSubscription from "../../../components/cards/pos/AddCustomerWithSubscription";
import ConfirmCheckout from "../POS/steps/ConfirmCheckout";
import BarcodeScannerModal from "../../../components/BarcodeScannerModal";
import {
  CurstomeCardSkeleton,
  FormSkeleton,
  FoundItemSkeleton,
} from "../../../components/skeleton/Card";
import Subscription from "../../../apps/device_financing/Subscription/pages/subscription";
import { handleNextStepAction } from "../../../apps/device_financing/Subscription/redux/layout/actions";
import { LoadingButton } from "@mui/lab";
import { myLayoutActions } from "../../../store/layout";
import SearchBarV2 from "../../../components/elements/SearchBarV2";
import { ReactComponent as AddCustomerPlaceholderImage } from "../../../assets/images/EnterCustomerInfoOption.svg";
import RegisterCustomer from "../../../apps/freelancerModule/pages/RegisterCustomer";
import { subLayoutActions } from "../../../apps/device_financing/Subscription/redux/layout";
const CreateSale = () => {
  const { auth, appSettings, wareHouse, customer, layout, subLayout } =
    useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addToCart, setAddToCart] = useState(false);
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isGettingCustomer, setIsGettingCustomer] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [scannedCode, setScannedCode] = useState<string>();
  const [newCustomer, setNewCustomer] = useState(false);
  const { cartId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };
  const handleCloseModal = () => {
    dispatch(myLayoutActions.setIsCustomerHasSubscription(false));
    setOpenModal(false);
    auth?.user?.profile === "freelancer"
      ? navigate("/cart")
      : navigate("/sale");
  };
  const isFound =
    wareHouse?.searchResult?.status === 200 &&
    wareHouse?.searchResult?.data?.isInShop &&
    wareHouse?.searchResult?.data?.product?.status !== "sold";

  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";
  const isSubmitted = wareHouse?.createdCart?.data?.isSubscriptionSubmitted;

  const productData = wareHouse?.searchResult;

  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;

  const onFinish = async (values: any) => {
    setIsSearching(true);
    try {
      auth?.token &&
        (await getAllShopStockAction(
          auth?.token,
          `?serialNumber=${values?.query}&shopId=${
            getActiveShop(auth?.user?.shop?.assigned)[
              appSettings?.selectedShopNumber
            ]?.shop?._id ?? auth?.userShop?.shopId
          }`
        )(dispatch));
    } catch (error) {
    } finally {
      setIsSearching(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (auth?.token && wareHouse?.isCartCreated && cartId) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  }, [auth?.token, cartId, dispatch]);

  useEffect(() => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myCustomerActions.setSelected(null));
  }, [dispatch, wareHouse?.isCartCreated]);

  const [current, setCurrent] = useState(0);

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const handleAddToCartInfo = async () => {
    setAddToCart(true);
    const product = wareHouse?.searchResult?.data?.product;
    const payload = {
      cart: cartId,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      payment: {
        amount: +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
        installementPayment:
          (ActivePlan && ActivePlan.at(0)?.initialPayment) || 0,
      },
    };

    try {
      auth?.token &&
        (await updateShopStockCartAction(
          auth?.token,
          cartId,
          payload
        )(dispatch));
      dispatch(myWareHouseActions.setSearchResult(null));
      await updateWareHouseDetailsAction(
        auth?.token,
        product?.wareHouseItem?._id,
        {
          dataActivated: false,
        }
      )(dispatch);
    } catch (e) {
    } finally {
      // setAddToCart(false)
    }
  };

  useEffect(() => {
    if (
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "paid" ||
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "cancelled"
    ) {
      setCurrent(1);
    }
  }, [wareHouse?.createdCart?.data?.status]);

  useEffect(() => {
    dispatch(myCustomerActions.setSelected(null));
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myWareHouseActions.setSearchResult(null));
  }, [dispatch]);

  useEffect(() => {
    const addCustomer = async () => {
      customer?.new?.data &&
        (await getOneCustomerByQueryAction(
          auth?.token,
          `?phone=${customer?.new?.data?.phone}`
        )(dispatch));
      dispatch(myCustomerActions.setNew(null));
      dispatch(myCustomerActions.setUpdated(null));
      dispatch(myCustomerActions.setSearchResult(null));
    };
    addCustomer();
  }, [auth?.token, customer?.new, customer?.searchResult, dispatch]);

  useEffect(() => {
    if (auth?.token && cartId) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  }, [auth?.token, cartId, dispatch]);

  useEffect(() => {
    const handleUpdateCart = async () => {
      setIsGettingCustomer(true);
      const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
        customer: customer?.searchResult[0]?._id,
      })(dispatch);
      if (res) {
        setIsGettingCustomer(false);
      }
    };
    if (customer?.searchResult?.length > 0 && auth?.token) {
      handleUpdateCart();
    }
  }, [customer?.searchResult, cartId, dispatch, auth?.token]);

  useEffect(() => {
    if (isSubmitted) {
      handleNextStepAction(4)(dispatch);
    }
  }, [isSubmitted, dispatch]);
  const [customerQuery, setCustomerQuery] = useState("");
  const handleOpenAddCustomer = () => {
    dispatch(subLayoutActions.setNewCustomer(true));
  };
  const handleOnSearchChange = (e: any) => {
    setCustomerQuery(e.target.value?.replace("+", "")?.trim());
  };
  const onSearch = async (e: any) => {
    e.preventDefault();
    auth?.token &&
      (await getOneCustomerByQueryAction(
        auth?.token,
        `?phone=${customerQuery}`
      )(dispatch));
  };
  return (
    <div className="text-black">
      {/* {!layout?.confirmCheckout ? ( */}
      <>
        <CustomModal
          openModal={layout?.isCustomerHasSubscription}
          closeModal={handleCloseModal}
          closable={false}
          component={
            <div>
              <Result
                status="warning"
                title={
                  <div>
                    {" "}
                    <p className="text-center text-[#030229B2] text-base">
                      This Customer has Active Subscription you can't procceed{" "}
                    </p>
                    <h1 className="text-center text-[#030229] text-base py-2">
                      Thank you{" "}
                    </h1>
                  </div>
                }
                extra={
                  <LoadingButton
                    variant="contained"
                    style={{ width: 100, height: 30, fontSize: "12px" }}
                    onClick={handleCloseModal}
                  >
                    Close
                  </LoadingButton>
                }
              />
            </div>
          }
        />
        <CustomModal
          title={
            <h1 className="text-[#0F0F47] text-lg font-medium">
              Select Selling Mode
            </h1>
          }
          width={600}
          openModal={layout?.isOpenSellingMode}
          closeModal={handleOk}
          component={<PamentModeDrower onClick={handleOk} />}
        />
        <div
          className={`flex  gap-5 ${
            wareHouse?.createdCart?.data?.list?.length > 0
              ? " flex-col-reverse lg:flex-row"
              : " flex-col lg:flex-row"
          }`}
        >
          <div
            className={`${
              [0, 1, 2, 4]?.includes(subLayout?.nextStep)
                ? "w-full lg:w-[50%] xl:w-[60%]"
                : "w-full"
            } h-[85vh]`}
          >
            {(wareHouse.createCartIsFetching ||
              !wareHouse?.createdCart?.data?.isSubscription) && (
              <>
                <div className="h-[48.5%] bg-white shadow-md rounded-md p-5 w-full">
                  <FoundItemSkeleton />
                </div>
                <div className="h-1/2 bg-white shadow-md rounded-md p-5 w-full mt-2">
                  <FormSkeleton />
                </div>
              </>
            )}

            {wareHouse?.createdCart?.data?.isSubscription === "pos" && (
              <>
                <div className=" h-fit md:h-[48.5%] bg-white shadow-md rounded-md p-5 w-full ">
                  {wareHouse.isFetching && !isSearching ? (
                    <FoundItemSkeleton />
                  ) : (
                    <>
                      {!isFound ? (
                        <div className="flex justify-center gap-x-2">
                          <Form onFinish={onFinish}>
                            <Form.Item
                              name="query"
                              className="rounded-lg flex"
                              // label="Enter serial number"
                              initialValue={scannedCode}
                            >
                              <SearchBar
                                isLoading={isSearching}
                                scannedCode={newSearch ? "" : scannedCode}
                                onChange={handleChange}
                                isDisabled={!isCartEmpty && isSubscripitonPage}
                              />
                            </Form.Item>
                            <div className="py-1">
                              {!wareHouse?.isFetching &&
                                wareHouse?.searchResult?.status === 200 &&
                                wareHouse?.searchResult?.data?.isInShop &&
                                wareHouse?.searchResult?.data?.product
                                  ?.status !== "sold" && (
                                  <p className="font-semibold text-green-500">
                                    Product found
                                  </p>
                                )}
                              {!wareHouse?.isFetching &&
                                wareHouse?.searchError?.status === 404 && (
                                  <p className="font-semibold text-red-500">
                                    Product not found
                                  </p>
                                )}
                              {!wareHouse?.isFetching &&
                                !wareHouse?.searchResult?.data?.isInShop && (
                                  <div className="font-semibold text-yellow-500">
                                    {wareHouse?.searchResult?.message}
                                  </div>
                                )}
                              {!wareHouse?.isFetching &&
                                wareHouse?.searchResult?.data?.isInShop &&
                                wareHouse?.searchResult?.data?.product
                                  ?.status === "sold" && (
                                  <div className="font-semibold text-yellow-500">
                                    The product is sold
                                  </div>
                                )}
                            </div>
                          </Form>
                          <div className="mt-10">
                            <BarcodeScannerModal getCode={getCode} />
                          </div>
                        </div>
                      ) : (
                        <ProductCard
                          onClick={handleAddToCartInfo}
                          data={productData}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className=" h-fit md:h-1/2 bg-white shadow-md rounded-md p-5 w-full mt-2">
                  {customer?.isFetching ||
                  (wareHouse?.isUpdateFetching && isGettingCustomer) ? (
                    <FormSkeleton />
                  ) : (
                    <div className="h-full bg-white p-2 rounded-lg overflow-y-auto">
                      <AddCustomer />
                    </div>
                  )}
                </div>
              </>
            )}

            {wareHouse?.createdCart?.data?.isSubscription ===
              "subscription" && (
              <>
                {auth?.user?.profile?.toLowerCase() !== "freelancer" ? (
                  <div className="bg-white shadow-md rounded-md 2xl:p-5 w-full h-full overflow-y-auto">
                    <Subscription isGettingCustomer={isGettingCustomer} />
                  </div>
                ) : (
                  <div>
                    {!subLayout?.newCustomer ? (
                      <div>
                        <div className="flex items-center justify-center py-4 space-x-2 pt-10">
                          <div className="rounded-lg">
                            <form method="get" onSubmit={onSearch}>
                              <SearchBarV2
                                placeholder="Input customer number"
                                onChange={handleOnSearchChange}
                                name="customer"
                                isLoading={
                                  customer.isFetching ||
                                  wareHouse?.isUpdateFetching
                                }
                              />
                            </form>
                          </div>
                          <button
                            onClick={() => handleOpenAddCustomer()}
                            className="shadow py-2 px-4 border bg-slate-300 rounded-md"
                          >
                            <PlusOutlined className="m-0 p-0" />
                          </button>
                        </div>
                        <div className="flex justify-center">
                          <AddCustomerPlaceholderImage className="h-44" />
                        </div>
                      </div>
                    ) : (
                      <RegisterCustomer setNewCustomer={setNewCustomer} />
                    )}
                  </div>
                )}
                {/* )} */}
              </>
            )}
          </div>

          {[0, 1, 2, 4]?.includes(subLayout?.nextStep) && (
            <div
              className={`bg-white rounded-md border border-[#0302291A] w-full lg:w-[50%] xl:w-[40%] mt-5 lg:mt-0 h-fit md:h-[85vh] shadow-md ${
                wareHouse?.createdCart?.data?.list?.length > 0
                  ? ""
                  : " hidden md:block"
              }`}
            >
              {wareHouse?.isFetching ||
              !wareHouse?.createdCart?.data?.isSubscription ||
              wareHouse?.hasRemovedDevice ? (
                <div className="p-6">
                  <CurstomeCardSkeleton />
                </div>
              ) : (
                <AddToCart />
              )}
            </div>
          )}
        </div>
      </>
      {/* // ) : (
      //   <ConfirmCheckout />
      // )} */}
    </div>
  );
};

export default CreateSale;
