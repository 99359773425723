import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";
import StockTransfer from "./StockTransfer";
import TeamleadTransfer from "./TeamleadTransfer";
import { useSelector } from "react-redux";
import StockForOneFreelancerTable from "../../../components/tables/StockForOneFreelancerTable";

const Transfer = () => {
  const { auth } = useSelector((state: any) => state);
  const handleBack = () => {
  };

  return (
    <div className="text-[#030229]">
      <Tabs defaultActiveKey="1">
        {auth?.user?.profile?.toLowerCase() !== "freelancer" && (
          <TabPane tab="Stock Transfer" key="1" className="text-[16px]">
            <StockTransfer />
          </TabPane>
        )}
        {(["freelancer", "team-leader"]?.includes(auth?.user?.profile) || ["admin", "dev"]?.includes(auth?.user?.role)) && (
          <TabPane tab="Stock Assignee" key="2">
            <TeamleadTransfer />
          </TabPane>
        )}
        {auth?.user?.profile?.toLowerCase() === "freelancer" && (
          <TabPane tab="My Stock" key="3">
            <StockForOneFreelancerTable userId={""} onBack={handleBack} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};
export default Transfer;
